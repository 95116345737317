import { GraphQLClient, RequestOptions } from 'graphql-request';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
type GraphQLClientRequestHeaders = RequestOptions['requestHeaders'];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** Date (isoformat) */
  Date: { input: any; output: any; }
  /** Date with time (isoformat) */
  DateTime: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](https://ecma-international.org/wp-content/uploads/ECMA-404_2nd_edition_december_2017.pdf). */
  JSON: { input: any; output: any; }
  /** Time (isoformat) */
  Time: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export type IAnonymizeCycleSelectionResponse = {
  __typename?: 'AnonymizeCycleSelectionResponse';
  data: Array<ICycle>;
};

export type IAnonymizedVideo = {
  __typename?: 'AnonymizedVideo';
  description: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  status: IAnonymizedVideoStatus;
  timestampEnd: Scalars['DateTime']['output'];
  timestampStart: Scalars['DateTime']['output'];
  video: IS3Object;
};

export type IAnonymizedVideoBookmark = {
  __typename?: 'AnonymizedVideoBookmark';
  id: Scalars['ID']['output'];
  video: IAnonymizedVideo;
};

export type IAnonymizedVideoBookmarkNotFound = IUserError & {
  __typename?: 'AnonymizedVideoBookmarkNotFound';
  message: Scalars['String']['output'];
};

export type IAnonymizedVideoCreationFailed = IUserError & {
  __typename?: 'AnonymizedVideoCreationFailed';
  message: Scalars['String']['output'];
};

export type IAnonymizedVideoFilter = {
  AND: InputMaybe<IAnonymizedVideoFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IAnonymizedVideoFilter>;
  OR: InputMaybe<IAnonymizedVideoFilter>;
  status: IAnonymizedVideoStatusFilter;
};

export type IAnonymizedVideoFiltersV2 = {
  dateRange: Array<Scalars['Date']['input']>;
  durationRange: InputMaybe<Array<Scalars['Float']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  onlyBookmarked: Scalars['Boolean']['input'];
  order: IOrdering;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  stationIds: Array<Scalars['ID']['input']>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IAnonymizedVideoIdFilter = {
  AND: InputMaybe<IAnonymizedVideoIdFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IAnonymizedVideoIdFilter>;
  OR: InputMaybe<IAnonymizedVideoIdFilter>;
  id: InputMaybe<IIdFilterLookup>;
};

export type IAnonymizedVideoIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IAnonymizedVideoInput = {
  kinesisVideoStream: IKinesisVideoStreamInput;
  metadata: InputMaybe<Scalars['JSON']['input']>;
  status: IAnonymizedVideoStatusInput;
  timestampEnd: Scalars['DateTime']['input'];
  timestampStart: Scalars['DateTime']['input'];
  video: IS3ObjectIdInput;
};

export type IAnonymizedVideoNotFound = IUserError & {
  __typename?: 'AnonymizedVideoNotFound';
  message: Scalars['String']['output'];
};

export type IAnonymizedVideoStatus = {
  __typename?: 'AnonymizedVideoStatus';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IAnonymizedVideoStatusFilter = {
  AND: InputMaybe<IAnonymizedVideoStatusFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IAnonymizedVideoStatusFilter>;
  OR: InputMaybe<IAnonymizedVideoStatusFilter>;
  name: InputMaybe<IStrFilterLookup>;
};

export type IAnonymizedVideoStatusInput = {
  name: Scalars['String']['input'];
};

export type IAnonymizedVideoUpdateInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  metadata: InputMaybe<Scalars['JSON']['input']>;
  status: IAnonymizedVideoStatusInput;
  video: InputMaybe<IS3ObjectIdInput>;
};

export type IAnonymizedVideoWithCycleAndBookmark = {
  __typename?: 'AnonymizedVideoWithCycleAndBookmark';
  bookmark: Maybe<IAnonymizedVideoBookmark>;
  cycle: ICycle;
  description: Maybe<Scalars['String']['output']>;
  duration: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  status: IAnonymizedVideoStatus;
  tagIds: Array<Scalars['ID']['output']>;
  timestampEnd: Scalars['DateTime']['output'];
  timestampStart: Scalars['DateTime']['output'];
  video: IS3Object;
};

export type IAnonymizedVideos = {
  __typename?: 'AnonymizedVideos';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
  videos: Array<IAnonymizedVideoWithCycleAndBookmark>;
};

export type IAreaAvgEventOccurrence = {
  __typename?: 'AreaAvgEventOccurrence';
  eventCount: Scalars['Int']['output'];
  eventType: IEventType;
  time: Scalars['Time']['output'];
};

export type IAreaAvgTimeSpent = {
  __typename?: 'AreaAvgTimeSpent';
  durationSeconds: Scalars['Int']['output'];
  time: Scalars['Time']['output'];
};

export type IAreaAvgWorkersCount = {
  __typename?: 'AreaAvgWorkersCount';
  time: Scalars['Time']['output'];
  timeSpentRatio: Scalars['Float']['output'];
  workerCount: Scalars['Float']['output'];
};

export type IAreaConnectionsFilter = {
  AND: InputMaybe<IAreaConnectionsFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IAreaConnectionsFilter>;
  OR: InputMaybe<IAreaConnectionsFilter>;
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  fromArea: Scalars['ID']['input'];
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IAreaConnectionsResponse = {
  __typename?: 'AreaConnectionsResponse';
  connections: Array<IConnection>;
};

export type IAreaCreatedResponse = IAreaHasOverlap | ICameraBoundingBox | ICameraNotFound | IDifferentGroupType | IGroupNotFound | IInvalidCameraBoundingBoxCoordinates | IMissingRequiredFields | IStationNotFound;

export type IAreaDeletedResponse = IAreaNotFound | IDeletedCameraBoundingBox;

export type IAreaGroup = {
  __typename?: 'AreaGroup';
  color: Array<Scalars['Int']['output']>;
  groupType: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IAreaGroupFilters = {
  customer: ICustomerIdInput;
  groupType: InputMaybe<Scalars['Int']['input']>;
};

export type IAreaGroupIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IAreaGroupNotFound = IUserError & {
  __typename?: 'AreaGroupNotFound';
  message: Scalars['String']['output'];
};

export type IAreaHasOverlap = IUserError & {
  __typename?: 'AreaHasOverlap';
  message: Scalars['String']['output'];
};

export type IAreaNotFound = IUserError & {
  __typename?: 'AreaNotFound';
  message: Scalars['String']['output'];
};

export type IAreaOfInterestFilters = {
  boxId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']>;
};

export type IAreaOfInterestStatistics = {
  __typename?: 'AreaOfInterestStatistics';
  avgAreaExitsPerHour: Scalars['Int']['output'];
  avgEventOccurrence: Array<IAreaAvgEventOccurrence>;
  avgTimeSpent: Array<IAreaAvgTimeSpent>;
  avgWorkerCount: Array<IAreaAvgWorkersCount>;
  timeSpentRatio: Scalars['Float']['output'];
};

export type IAreaOfInterestStatisticsDto = {
  __typename?: 'AreaOfInterestStatisticsDTO';
  avgTimeSpent: Array<IAreaAvgTimeSpent>;
  avgWorkerCount: Array<IAreaAvgWorkersCount>;
  timeSpentRatio: Scalars['Float']['output'];
};

export type IAreaOfInterestStatisticsFiltersV2 = {
  boxId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IAreaOfInterestsStatisticsResponse = IAreaOfInterestStatisticsDto | IMissingRequiredFields;

export type IAreaUpdatedResponse = IAreaHasOverlap | IAreaNotFound | ICameraBoundingBox | ICameraNotFound | IDifferentGroupType | IGroupNotFound | IInvalidCameraBoundingBoxCoordinates | IMissingRequiredFields | IStationNotFound;

export type IAvgTotalStatistics = {
  __typename?: 'AvgTotalStatistics';
  mean: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type IBevStatisticsResponse = IAreaOfInterestStatistics | IMissingRequiredFields;

export type IBoundingBoxAndCameraStationBothNull = IUserError & {
  __typename?: 'BoundingBoxAndCameraStationBothNull';
  message: Scalars['String']['output'];
};

export type ICamera = {
  __typename?: 'Camera';
  areas: Array<ICameraBoundingBox>;
  camType: Scalars['Int']['output'];
  deactivated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  heatmap: Array<IHeatMap>;
  id: Scalars['ID']['output'];
  kinesisVideoStream: Maybe<IKinesisVideoStream>;
  lanIp: Scalars['String']['output'];
  latestSnapshot: IS3Object;
  name: Scalars['String']['output'];
  resHeight: Scalars['Int']['output'];
  resWidth: Scalars['Int']['output'];
  sensors: Array<IVirtualSensorToCamera>;
  stations: Array<IStation>;
  walkingRoutes: Array<IWalkingRoute>;
};


export type ICameraHeatmapArgs = {
  filters?: InputMaybe<IHeatMapFilter>;
};


export type ICameraWalkingRoutesArgs = {
  filters?: InputMaybe<IWalkingRouteFilter>;
};

export type ICameraBoundingBox = {
  __typename?: 'CameraBoundingBox';
  camera: IDjangoModelType;
  cameraStation: Maybe<ICameraStation>;
  coordinates: Scalars['JSON']['output'];
  downstreamAreas: Array<ICameraBoundingBox>;
  group: IAreaGroup;
  id: Scalars['ID']['output'];
  virtualBbox: Maybe<ICameraBoundingBox>;
  walkingRoutes: Array<IWalkingRoute>;
};


export type ICameraBoundingBoxWalkingRoutesArgs = {
  filters?: InputMaybe<IWalkingRouteFilter>;
};

export type ICameraBoundingBoxDeleteInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ICameraBoundingBoxFilter = {
  AND: InputMaybe<ICameraBoundingBoxFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ICameraBoundingBoxFilter>;
  OR: InputMaybe<ICameraBoundingBoxFilter>;
  camera: InputMaybe<ICameraFilter>;
  cameraStation: InputMaybe<ICameraStationFilter>;
  id: InputMaybe<Scalars['ID']['input']>;
  virtualBboxId: InputMaybe<Scalars['ID']['input']>;
};

export type ICameraBoundingBoxIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ICameraBoundingBoxInput = {
  camera: ICameraIdInput;
  coordinates: Scalars['JSON']['input'];
  group: IAreaGroupIdInput;
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ICameraFilter = {
  AND: InputMaybe<ICameraFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ICameraFilter>;
  OR: InputMaybe<ICameraFilter>;
  camType: InputMaybe<IIntFilterLookup>;
  deactivated: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  stations: InputMaybe<IStationFilter>;
};

export type ICameraIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ICameraNotFound = IUserError & {
  __typename?: 'CameraNotFound';
  message: Scalars['String']['output'];
};

export type ICameraStation = {
  __typename?: 'CameraStation';
  id: Scalars['ID']['output'];
  station: IStation;
};

export type ICameraStationFilter = {
  AND: InputMaybe<ICameraStationFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ICameraStationFilter>;
  OR: InputMaybe<ICameraStationFilter>;
  id: InputMaybe<Scalars['ID']['input']>;
  station: InputMaybe<IStationFilter>;
};

export type ICameraStationInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IConnection = {
  __typename?: 'Connection';
  cameraBoundingBox: ICameraBoundingBox;
  count: Scalars['Int']['output'];
};

export type ICreateAnonymizedVideoBookmarkResponse = IAnonymizedVideoBookmark | IAnonymizedVideoCreationFailed | IAnonymizedVideoNotFound;

export type ICreateAreaGroupInput = {
  color: Array<Scalars['Int']['input']>;
  customer: ICustomerIdInput;
  id: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type ICreateAreaGroupResponse = IAreaGroup | ICustomerNotFound | IInvalidGroupColor | IInvalidGroupName;

export type ICreateShiftGroupInput = {
  end: Scalars['Time']['input'];
  id: InputMaybe<Scalars['ID']['input']>;
  line: ILineIdInput;
  name: Scalars['String']['input'];
  start: Scalars['Time']['input'];
};

export type ICreateShiftGroupResponse = IInvalidGroupName | IInvalidTimes | ILineNotFound | IShiftGroup;

export type ICreateShiftVariantInput = {
  appliedAt: Scalars['Date']['input'];
  disabled: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  repeatEvery: InputMaybe<Scalars['Int']['input']>;
  repeatInterval: InputMaybe<Scalars['String']['input']>;
  repeatStart: Scalars['Date']['input'];
  shiftGroup: IShiftGroupIdInput;
  weekdaysTargets: InputMaybe<Array<InputMaybe<IShiftTargetInput>>>;
  workingTimes: Array<Array<Scalars['Time']['input']>>;
};

export type ICreateShiftVariantOverrideInput = {
  date: Scalars['Date']['input'];
  id: Scalars['ID']['input'];
  targets: IShiftTargetInput;
};

export type ICreateShiftVariantOverrideResponse = ICreatedShiftVariantOverrideId | IInvalidShiftVariant | IShiftVariantNotFound;

export type ICreateShiftVariantResponse = IInvalidGroupName | IInvalidShiftVariant | IShiftGroupNotFound | IShiftVariant;

export type ICreateTagResponse = ITag | IUnauthorizedError | IUnexpectedError;

export type ICreateWalkingRouteFailure = IUserError & {
  __typename?: 'CreateWalkingRouteFailure';
  message: Scalars['String']['output'];
};

export type ICreateWalkingRouteInput = {
  camera: InputMaybe<ICameraIdInput>;
  path: Array<Array<Scalars['Int']['input']>>;
  routeId: InputMaybe<Scalars['String']['input']>;
  timeRange: Array<Scalars['DateTime']['input']>;
};

export type ICreatedShiftVariantOverrideId = {
  __typename?: 'CreatedShiftVariantOverrideId';
  id: Scalars['ID']['output'];
};

export type ICustomer = {
  __typename?: 'Customer';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type ICustomerFilter = {
  AND: InputMaybe<ICustomerFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ICustomerFilter>;
  OR: InputMaybe<ICustomerFilter>;
  id: InputMaybe<IIdFilterLookup>;
  name: InputMaybe<IStrFilterLookup>;
};

export type ICustomerIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ICustomerNotFound = IUserError & {
  __typename?: 'CustomerNotFound';
  message: Scalars['String']['output'];
};

export type ICycle = {
  __typename?: 'Cycle';
  anonymizedVideo: Maybe<IAnonymizedVideo>;
  camera: ICamera;
  duration: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  productWorkerDurationShare: Scalars['Float']['output'];
  station: IStation;
  timestampEnd: Scalars['DateTime']['output'];
  timestampStart: Scalars['DateTime']['output'];
};

export type ICycleCountByTimeDataPointDto = {
  __typename?: 'CycleCountByTimeDataPointDTO';
  aggregationCount: Scalars['Float']['output'];
  aggregationTarget: Scalars['Float']['output'];
  datetime: Scalars['DateTime']['output'];
};

export type ICycleDto = {
  __typename?: 'CycleDTO';
  cycleId: Scalars['ID']['output'];
  timestampEnd: Scalars['DateTime']['output'];
  timestampStart: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  variantId: Scalars['ID']['output'];
  videoId: Maybe<Scalars['ID']['output']>;
};

export type ICycleDurationStatistics = {
  __typename?: 'CycleDurationStatistics';
  mean: Scalars['Float']['output'];
  median: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
  variance: Scalars['Float']['output'];
};

export type ICycleFilter = {
  AND: InputMaybe<ICycleFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ICycleFilter>;
  OR: InputMaybe<ICycleFilter>;
  anonymizedVideo: InputMaybe<IAnonymizedVideoFilter>;
  camera: InputMaybe<ICameraFilter>;
  duration: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<IIdFilterLookup>;
  limit: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<Scalars['String']['input']>>;
  productWorkerDurationShare: InputMaybe<IFloatFilterLookup>;
  station: InputMaybe<IStationFilter>;
  timestampEnd: InputMaybe<IDatetimeFilterLookup>;
  timestampStart: InputMaybe<IDatetimeFilterLookup>;
};

export type ICycleOverTimePoint = {
  __typename?: 'CycleOverTimePoint';
  count: Scalars['Float']['output'];
  date: Scalars['Date']['output'];
  datetime: Scalars['DateTime']['output'];
  duration: Scalars['Int']['output'];
  stationId: Scalars['String']['output'];
};

export type ICycleOverTimeResponse = ICycleOverTimeStatistics | IMissingRequiredFields;

export type ICycleOverTimeStatistics = {
  __typename?: 'CycleOverTimeStatistics';
  data: Array<ICycleOverTimePoint>;
  timeGranularity: ITimeGranularity;
};

export type ICycleStatistics = {
  __typename?: 'CycleStatistics';
  cycleCount: Scalars['Int']['output'];
  productDuration: ICycleDurationStatistics;
  productWorkerDuration: ICycleDurationStatistics;
  stationId: Scalars['ID']['output'];
};

export type ICycleStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  stationIds: Array<Scalars['ID']['input']>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IDatetimeFilterLookup = {
  contains: InputMaybe<Scalars['DateTime']['input']>;
  endsWith: InputMaybe<Scalars['DateTime']['input']>;
  exact: InputMaybe<Scalars['DateTime']['input']>;
  gt: InputMaybe<Scalars['DateTime']['input']>;
  gte: InputMaybe<Scalars['DateTime']['input']>;
  iContains: InputMaybe<Scalars['DateTime']['input']>;
  iEndsWith: InputMaybe<Scalars['DateTime']['input']>;
  iExact: InputMaybe<Scalars['DateTime']['input']>;
  iRegex: InputMaybe<Scalars['String']['input']>;
  iStartsWith: InputMaybe<Scalars['DateTime']['input']>;
  inList: InputMaybe<Array<Scalars['DateTime']['input']>>;
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  lt: InputMaybe<Scalars['DateTime']['input']>;
  lte: InputMaybe<Scalars['DateTime']['input']>;
  range: InputMaybe<Array<Scalars['DateTime']['input']>>;
  regex: InputMaybe<Scalars['String']['input']>;
  startsWith: InputMaybe<Scalars['DateTime']['input']>;
};

export type IDeleteAnonymizedVideoBookmarkResponse = IAnonymizedVideoBookmarkNotFound | IAnonymizedVideoNotFound | IDeletedAnonymizedVideoBookmarkId;

export type IDeleteAreaGroupResponse = IAreaGroupNotFound | IDeletedAreaGroupId;

export type IDeleteShiftGroupInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IDeleteShiftGroupResponse = IDeletedShiftGroupId | IShiftGroupNotFound;

export type IDeleteShiftVariantInput = {
  appliedAt: Scalars['Date']['input'];
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IDeleteShiftVariantResponse = IDeletedShiftVariantId | IShiftVariantNotFound;

export type IDeleteTagInput = {
  id: Scalars['ID']['input'];
};

export type IDeleteTagResponse = IDeletedTag | IUnexpectedError;

export type IDeletedAnonymizedVideoBookmarkId = {
  __typename?: 'DeletedAnonymizedVideoBookmarkId';
  videoId: Scalars['ID']['output'];
};

export type IDeletedAreaGroupId = {
  __typename?: 'DeletedAreaGroupId';
  id: Scalars['ID']['output'];
};

export type IDeletedCameraBoundingBox = {
  __typename?: 'DeletedCameraBoundingBox';
  id: Scalars['ID']['output'];
};

export type IDeletedNotificationRuleId = {
  __typename?: 'DeletedNotificationRuleId';
  id: Scalars['ID']['output'];
};

export type IDeletedShiftGroupId = {
  __typename?: 'DeletedShiftGroupId';
  id: Scalars['ID']['output'];
};

export type IDeletedShiftVariantId = {
  __typename?: 'DeletedShiftVariantId';
  id: Scalars['ID']['output'];
};

export type IDeletedTag = {
  __typename?: 'DeletedTag';
  id: Scalars['ID']['output'];
};

export type IDifferentGroupType = IUserError & {
  __typename?: 'DifferentGroupType';
  message: Scalars['String']['output'];
};

export type IDjangoModelType = {
  __typename?: 'DjangoModelType';
  pk: Scalars['ID']['output'];
};

export type IEdgeCameraConfig = {
  __typename?: 'EdgeCameraConfig';
  camera: ICamera;
  croppingArea: ICameraBoundingBox;
  modelConfig: IEdgeModelParamsConfEdgeModelPersonParamsConfEdgeModelAnonymizationParamsConf;
};

export type IEdgeDevice = {
  __typename?: 'EdgeDevice';
  cameras: Array<ICamera>;
  configs: Array<IEdgeCameraConfig>;
  customer: ICustomer;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type IEdgeDeviceCamerasArgs = {
  filters: InputMaybe<ICameraFilter>;
};


export type IEdgeDeviceConfigsArgs = {
  filters: IEdgeDevicesConfigFilter;
};

export enum IEdgeDeviceConfigType {
  Anonymization = 'ANONYMIZATION',
  CycleEventDetector = 'CYCLE_EVENT_DETECTOR',
  PersonEventDetector = 'PERSON_EVENT_DETECTOR'
}

export type IEdgeDeviceInput = {
  id: InputMaybe<Scalars['Int']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type IEdgeDeviceNotFound = IUserError & {
  __typename?: 'EdgeDeviceNotFound';
  message: Scalars['String']['output'];
};

export type IEdgeDevicesConfigFilter = {
  type: InputMaybe<IEdgeDeviceConfigType>;
};

export type IEdgeDevicesFilter = {
  AND: InputMaybe<IEdgeDevicesFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IEdgeDevicesFilter>;
  OR: InputMaybe<IEdgeDevicesFilter>;
  customer: InputMaybe<ICustomerFilter>;
  deactivated: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type IEdgeDevicesResponse = {
  __typename?: 'EdgeDevicesResponse';
  data: Array<IEdgeDevice>;
};

export type IEdgeModelAnonymizationParamsConf = {
  __typename?: 'EdgeModelAnonymizationParamsConf';
  batchSize: Maybe<Scalars['Int']['output']>;
  device: Scalars['String']['output'];
  durationThresholds: Maybe<Array<Scalars['Int']['output']>>;
  maskColor: Maybe<Array<Scalars['Int']['output']>>;
  outputTransform: Maybe<IEdgeModelParamsConfPersonTransformation>;
  samplerate: Maybe<Scalars['Int']['output']>;
  targetFramerate: Maybe<Scalars['Int']['output']>;
  threshold: Maybe<Scalars['Float']['output']>;
  torchModelPath: Scalars['String']['output'];
  transform: Maybe<IEdgeModelParamsConfPersonTransformation>;
};

export type IEdgeModelParamsConf = {
  __typename?: 'EdgeModelParamsConf';
  batchSize: Maybe<Scalars['Int']['output']>;
  detectionConf: Maybe<Scalars['Float']['output']>;
  device: Scalars['String']['output'];
  disableResetEvents: Maybe<Scalars['Boolean']['output']>;
  firstLastSeenMargin: Maybe<Scalars['Float']['output']>;
  gaussianStd: Maybe<Scalars['Float']['output']>;
  ignoreFsRule: Maybe<Scalars['Boolean']['output']>;
  ignoreLsRule: Maybe<Scalars['Boolean']['output']>;
  inputSize: Array<Scalars['Int']['output']>;
  leftRight: Maybe<Scalars['Float']['output']>;
  modelPath: Scalars['String']['output'];
  problemType: Scalars['String']['output'];
  productClasses: Maybe<Array<Scalars['Int']['output']>>;
  resetEventsAfter: Maybe<Scalars['Int']['output']>;
  samplerate: Scalars['Int']['output'];
  trackMinLength: Maybe<Scalars['Int']['output']>;
  transformations: Maybe<Array<IEdgeModelParamsConfTransformation>>;
  windowLength: Maybe<Scalars['Int']['output']>;
};

export type IEdgeModelParamsConfEdgeModelPersonParamsConfEdgeModelAnonymizationParamsConf = IEdgeModelAnonymizationParamsConf | IEdgeModelParamsConf | IEdgeModelPersonParamsConf;

export type IEdgeModelParamsConfPersonTransformation = {
  __typename?: 'EdgeModelParamsConfPersonTransformation';
  transforms: Maybe<Array<IEdgeModelParamsConfTransformation>>;
};

export type IEdgeModelParamsConfTransformation = {
  __typename?: 'EdgeModelParamsConfTransformation';
  Target_: Scalars['String']['output'];
  height: Maybe<Scalars['Int']['output']>;
  kernelSize: Maybe<Scalars['Int']['output']>;
  mean: Maybe<Array<Scalars['Float']['output']>>;
  std: Maybe<Array<Scalars['Float']['output']>>;
  transposeMask: Maybe<Scalars['Boolean']['output']>;
  width: Maybe<Scalars['Int']['output']>;
};

export type IEdgeModelPersonParamsConf = {
  __typename?: 'EdgeModelPersonParamsConf';
  detector: Maybe<IEdgeModelPersonParamsConfDetector>;
  generator: Maybe<IEdgeModelPersonParamsConfGenerator>;
  outputPath: Maybe<Scalars['String']['output']>;
  routeFilters: Maybe<Array<IEdgeModelPersonParamsConfRouteFilter>>;
  tracker: Maybe<IEdgeModelPersonParamsConfTracker>;
};

export type IEdgeModelPersonParamsConfDetector = {
  __typename?: 'EdgeModelPersonParamsConfDetector';
  scalingFactor: Maybe<Scalars['Float']['output']>;
  threshold: Maybe<Scalars['Float']['output']>;
  weightsPath: Maybe<Scalars['String']['output']>;
};

export type IEdgeModelPersonParamsConfGenerator = {
  __typename?: 'EdgeModelPersonParamsConfGenerator';
  areas: Maybe<Array<ICameraBoundingBox>>;
  batchSize: Maybe<Scalars['Int']['output']>;
  cameraId: Maybe<Scalars['Int']['output']>;
  samplerate: Maybe<Scalars['Int']['output']>;
  transform: Maybe<IEdgeModelParamsConfPersonTransformation>;
  videoOutPath: Maybe<Scalars['String']['output']>;
  videoPath: Maybe<Scalars['String']['output']>;
  videoTimestamp: Maybe<Scalars['Int']['output']>;
  walkingRoutes: Maybe<Scalars['Boolean']['output']>;
};

export type IEdgeModelPersonParamsConfRouteFilter = {
  __typename?: 'EdgeModelPersonParamsConfRouteFilter';
  Target_: Scalars['String']['output'];
  sampleRate: Maybe<Scalars['Int']['output']>;
  threshold: Maybe<Scalars['Float']['output']>;
};

export type IEdgeModelPersonParamsConfTracker = {
  __typename?: 'EdgeModelPersonParamsConfTracker';
  frameCount: Maybe<Scalars['Int']['output']>;
  maxAge: Maybe<Scalars['Int']['output']>;
  minHits: Maybe<Scalars['Int']['output']>;
  threshold: Maybe<Scalars['Int']['output']>;
};

export type IEntitiesWithMetrics = {
  timeGranularity: ITimeGranularity;
};

export type IEventCreatedResponse = IBoundingBoxAndCameraStationBothNull | IEventsBatchResponse | IMissingRequiredFields;

export type IEventDto = {
  __typename?: 'EventDTO';
  areaId: Scalars['ID']['output'];
  cameraId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  metadata: Scalars['JSON']['output'];
  stationId: Scalars['ID']['output'];
  timestamp: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
};

export type IEventInput = {
  boundingBox: InputMaybe<ICameraBoundingBoxIdInput>;
  cameraStation: InputMaybe<ICameraStationInput>;
  id: InputMaybe<Scalars['ID']['input']>;
  metadata: InputMaybe<Scalars['JSON']['input']>;
  tags: InputMaybe<Array<Scalars['String']['input']>>;
  timestamp: Scalars['DateTime']['input'];
  type: IEventTypeInput;
};

export type IEventNoId = {
  __typename?: 'EventNoId';
  id: Scalars['ID']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  timestamp: Scalars['DateTime']['output'];
  type: IEventType;
};

export type IEventType = {
  __typename?: 'EventType';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IEventTypeInput = {
  description: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
};

export type IEventsBatchResponse = {
  __typename?: 'EventsBatchResponse';
  data: Array<IEventNoId>;
  failureReasons: Array<Scalars['String']['output']>;
  failures: Scalars['Int']['output'];
  invalidEvents: Array<IEventNoId>;
};

export type IFactoriesWithMetrics = IEntitiesWithMetrics & {
  __typename?: 'FactoriesWithMetrics';
  data: Array<IFactoryWithMetrics>;
  timeGranularity: ITimeGranularity;
};

export type IFactoriesWithMetricsResponse = IFactoriesWithMetrics | IMissingRequiredFields;

export type IFactory = {
  __typename?: 'Factory';
  customer: ICustomer;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lines: Array<ILine>;
  name: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
};


export type IFactoryLinesArgs = {
  filters?: InputMaybe<ILineFilter>;
};

export type IFactoryDto = {
  __typename?: 'FactoryDTO';
  customerId: Scalars['ID']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lines: Array<ILineDto>;
  name: Scalars['String']['output'];
};

export type IFactoryFilter = {
  AND: InputMaybe<IFactoryFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IFactoryFilter>;
  OR: InputMaybe<IFactoryFilter>;
  customer: InputMaybe<ICustomerFilter>;
  deactivated: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<IIdFilterLookup>;
};

export type IFactoryReportingFilters = {
  dateRanges: Array<Array<Scalars['Date']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  filterPeriods: InputMaybe<Array<Array<Scalars['DateTime']['input']>>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRanges: Array<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IFactoryWithMetrics = IWithMetrics & {
  __typename?: 'FactoryWithMetrics';
  id: Scalars['ID']['output'];
  metrics: Array<IKpiMetric>;
  name: Scalars['String']['output'];
  outputFactor: Scalars['Float']['output'];
  shortName: Scalars['String']['output'];
};

export type IFloatFilterLookup = {
  contains: InputMaybe<Scalars['Float']['input']>;
  endsWith: InputMaybe<Scalars['Float']['input']>;
  exact: InputMaybe<Scalars['Float']['input']>;
  gt: InputMaybe<Scalars['Float']['input']>;
  gte: InputMaybe<Scalars['Float']['input']>;
  iContains: InputMaybe<Scalars['Float']['input']>;
  iEndsWith: InputMaybe<Scalars['Float']['input']>;
  iExact: InputMaybe<Scalars['Float']['input']>;
  iRegex: InputMaybe<Scalars['String']['input']>;
  iStartsWith: InputMaybe<Scalars['Float']['input']>;
  inList: InputMaybe<Array<Scalars['Float']['input']>>;
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  lt: InputMaybe<Scalars['Float']['input']>;
  lte: InputMaybe<Scalars['Float']['input']>;
  range: InputMaybe<Array<Scalars['Float']['input']>>;
  regex: InputMaybe<Scalars['String']['input']>;
  startsWith: InputMaybe<Scalars['Float']['input']>;
};

export type IGroupNotFound = IUserError & {
  __typename?: 'GroupNotFound';
  message: Scalars['String']['output'];
};

export type IHeatMap = {
  __typename?: 'HeatMap';
  camera: ICamera;
  heatmap: Scalars['JSON']['output'];
};

export type IHeatMapFilter = {
  AND: InputMaybe<IHeatMapFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IHeatMapFilter>;
  OR: InputMaybe<IHeatMapFilter>;
  camera: ICameraFilter;
  dateRange: InputMaybe<Array<Scalars['Date']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IHistogramBin = {
  __typename?: 'HistogramBin';
  cycleCount: Scalars['Float']['output'];
  cycleSamples: Array<IInlineHistogramCycle>;
  range: Array<Scalars['Float']['output']>;
};

export type IHistogramFilters = {
  binWindow: InputMaybe<Scalars['Float']['input']>;
  dateRange: Array<Scalars['Date']['input']>;
  disableOutputFactor: InputMaybe<Scalars['Boolean']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  maxSideCount: InputMaybe<Scalars['Int']['input']>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  stationId: Scalars['ID']['input'];
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IIdFilterLookup = {
  contains: InputMaybe<Scalars['ID']['input']>;
  endsWith: InputMaybe<Scalars['ID']['input']>;
  exact: InputMaybe<Scalars['ID']['input']>;
  gt: InputMaybe<Scalars['ID']['input']>;
  gte: InputMaybe<Scalars['ID']['input']>;
  iContains: InputMaybe<Scalars['ID']['input']>;
  iEndsWith: InputMaybe<Scalars['ID']['input']>;
  iExact: InputMaybe<Scalars['ID']['input']>;
  iRegex: InputMaybe<Scalars['String']['input']>;
  iStartsWith: InputMaybe<Scalars['ID']['input']>;
  inList: InputMaybe<Array<Scalars['ID']['input']>>;
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  lt: InputMaybe<Scalars['ID']['input']>;
  lte: InputMaybe<Scalars['ID']['input']>;
  range: InputMaybe<Array<Scalars['ID']['input']>>;
  regex: InputMaybe<Scalars['String']['input']>;
  startsWith: InputMaybe<Scalars['ID']['input']>;
};

export type IINotificationRuleContext = {
  periodSeconds: Scalars['Int']['output'];
  thresholdType: IThresholdType;
  thresholdValue: Scalars['Int']['output'];
};

export type IIShiftTarget = {
  cycleTime: Scalars['Int']['output'];
  output: Scalars['Int']['output'];
  persons: Scalars['Int']['output'];
};

export type IInlineHistogramCycle = {
  __typename?: 'InlineHistogramCycle';
  id: Scalars['Int']['output'];
  timestampEnd: Scalars['Time']['output'];
  timestampStart: Scalars['Time']['output'];
};

export type IIntFilterLookup = {
  contains: InputMaybe<Scalars['Int']['input']>;
  endsWith: InputMaybe<Scalars['Int']['input']>;
  exact: InputMaybe<Scalars['Int']['input']>;
  gt: InputMaybe<Scalars['Int']['input']>;
  gte: InputMaybe<Scalars['Int']['input']>;
  iContains: InputMaybe<Scalars['Int']['input']>;
  iEndsWith: InputMaybe<Scalars['Int']['input']>;
  iExact: InputMaybe<Scalars['Int']['input']>;
  iRegex: InputMaybe<Scalars['String']['input']>;
  iStartsWith: InputMaybe<Scalars['Int']['input']>;
  inList: InputMaybe<Array<Scalars['Int']['input']>>;
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  lt: InputMaybe<Scalars['Int']['input']>;
  lte: InputMaybe<Scalars['Int']['input']>;
  range: InputMaybe<Array<Scalars['Int']['input']>>;
  regex: InputMaybe<Scalars['String']['input']>;
  startsWith: InputMaybe<Scalars['Int']['input']>;
};

export type IInvalidCameraBoundingBoxCoordinates = IUserError & {
  __typename?: 'InvalidCameraBoundingBoxCoordinates';
  message: Scalars['String']['output'];
};

export type IInvalidDateTimeRange = IUserError & {
  __typename?: 'InvalidDateTimeRange';
  message: Scalars['String']['output'];
};

export type IInvalidGroupColor = IUserError & {
  __typename?: 'InvalidGroupColor';
  message: Scalars['String']['output'];
};

export type IInvalidGroupName = IUserError & {
  __typename?: 'InvalidGroupName';
  message: Scalars['String']['output'];
};

export type IInvalidPath = IUserError & {
  __typename?: 'InvalidPath';
  message: Scalars['String']['output'];
};

export type IInvalidPayloadContent = IUserError & {
  __typename?: 'InvalidPayloadContent';
  message: Scalars['String']['output'];
};

export type IInvalidShiftVariant = IUserError & {
  __typename?: 'InvalidShiftVariant';
  message: Scalars['String']['output'];
};

export type IInvalidTimes = IUserError & {
  __typename?: 'InvalidTimes';
  message: Scalars['String']['output'];
};

export type IKeyValueData = {
  __typename?: 'KeyValueData';
  key: Scalars['String']['output'];
  value: Scalars['Float']['output'];
};

export type IKinesisVideoStream = {
  __typename?: 'KinesisVideoStream';
  arn: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type IKinesisVideoStreamInput = {
  arn: Scalars['String']['input'];
};

export type IKpiDataPoint = {
  __typename?: 'KpiDataPoint';
  date: Scalars['Date']['output'];
  value: Scalars['Float']['output'];
};

export type IKpiMetric = {
  __typename?: 'KpiMetric';
  average: Scalars['Float']['output'];
  target: Scalars['Float']['output'];
  type: IKpiType;
  values: Array<IKpiMetricValue>;
};

export enum IKpiMetricType {
  Activity = 'ACTIVITY',
  AvgCycleTime = 'AVG_CYCLE_TIME',
  Output = 'OUTPUT',
  Persons = 'PERSONS',
  TotalCycleTime = 'TOTAL_CYCLE_TIME'
}

export type IKpiMetricValue = {
  __typename?: 'KpiMetricValue';
  overTime: Array<IKpiDataPoint>;
  type: IKpiValueType;
  value: Scalars['Float']['output'];
};

export enum IKpiType {
  Activity = 'ACTIVITY',
  CycleCount = 'CYCLE_COUNT',
  CycleTime = 'CYCLE_TIME',
  Interruptions = 'INTERRUPTIONS',
  Output = 'OUTPUT',
  Productivity = 'PRODUCTIVITY',
  Tact = 'TACT',
  Workers = 'WORKERS'
}

export enum IKpiValueType {
  ActivityActive = 'ACTIVITY_ACTIVE',
  ActivityEmpty = 'ACTIVITY_EMPTY',
  ActivityIdle = 'ACTIVITY_IDLE',
  ActivityInterrupted = 'ACTIVITY_INTERRUPTED',
  Actual = 'ACTUAL',
  Differential = 'DIFFERENTIAL'
}

export type ILine = {
  __typename?: 'Line';
  description: Scalars['String']['output'];
  factory: IFactory;
  id: Scalars['ID']['output'];
  inTraining: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shifts: Array<IShiftGroup>;
  shortName: Scalars['String']['output'];
  stations: Array<IStation>;
};


export type ILineStationsArgs = {
  filters?: InputMaybe<IStationFilter>;
};

export type ILineAccumulatedByTimeStatisticsDto = {
  __typename?: 'LineAccumulatedByTimeStatisticsDTO';
  data: Array<ICycleCountByTimeDataPointDto>;
  timeGranularity: ITimeGranularity;
  workingHours: Maybe<Array<Array<Scalars['DateTime']['output']>>>;
};

export type ILineAccumulatedByTimeStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineAccumulatedByTimeStatisticsResponse = ILineAccumulatedByTimeStatisticsDto | ILineStatisticsNotFound | IMissingRequiredFields;

export type ILineActivityPerStationStatisticsDto = {
  __typename?: 'LineActivityPerStationStatisticsDTO';
  data: Array<IStationActivityDto>;
};

export type ILineActivityPerStationStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  productIds: InputMaybe<Array<Scalars['ID']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineActivityPerStationStatisticsResponse = ILineActivityPerStationStatisticsDto | ILineStatisticsNotFound;

export type ILineAverageCycleTimeByStationDto = {
  __typename?: 'LineAverageCycleTimeByStationDTO';
  data: Array<IStationAverageCycleTimeDto>;
  taktTime: Scalars['Float']['output'];
};

export type ILineAverageCycleTimeByStationResponse = ILineAverageCycleTimeByStationDto | ILineStatisticsNotFound;

export type ILineAverageNumberOfWorkersDto = {
  __typename?: 'LineAverageNumberOfWorkersDTO';
  target: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ILineAverageNumberOfWorkersFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  productIds: InputMaybe<Array<Scalars['ID']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  stationIds: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineAverageNumberOfWorkersResponse = ILineAverageNumberOfWorkersDto | ILineStatisticsNotFound;

export type ILineDto = {
  __typename?: 'LineDTO';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  inTraining: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  shifts: Array<ILineShift>;
  stations: Array<IStationDto>;
};

export type ILineFilter = {
  AND: InputMaybe<ILineFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ILineFilter>;
  OR: InputMaybe<ILineFilter>;
  deactivated: InputMaybe<Scalars['Boolean']['input']>;
  factory: InputMaybe<IFactoryFilter>;
  id: InputMaybe<IIdFilterLookup>;
  limit: InputMaybe<Scalars['Int']['input']>;
  orderBy: InputMaybe<Array<Scalars['String']['input']>>;
};

export type ILineIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type ILineNotFound = IUserError & {
  __typename?: 'LineNotFound';
  message: Scalars['String']['output'];
};

export type ILineOutputPerStationStatisticsDto = {
  __typename?: 'LineOutputPerStationStatisticsDTO';
  data: Array<IStationOutputDto>;
};

export type ILineOutputPerStationStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  productIds: InputMaybe<Array<Scalars['ID']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineOutputPerStationStatisticsResponse = ILineOutputPerStationStatisticsDto | ILineStatisticsNotFound;

export type ILineOverallStatisticsDto = {
  __typename?: 'LineOverallStatisticsDTO';
  activeTimePercentage: Scalars['Float']['output'];
  meanCycleTime: ILineStatisticsOutputDto;
  output: ILineStatisticsOutputDto;
};

export type ILineOverallStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  productIds: InputMaybe<Array<Scalars['ID']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  stationIds: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineOverallStatisticsResponse = ILineOverallStatisticsDto | ILineStatisticsNotFound;

export type ILineShift = {
  __typename?: 'LineShift';
  end: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  start: Scalars['Time']['output'];
};

export type ILineStatisticsFilters = {
  dateRange: Array<Scalars['Date']['input']>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  lineId: Scalars['ID']['input'];
  productIds: InputMaybe<Array<Scalars['ID']['input']>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  tagIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILineStatisticsNotFound = IUserError & {
  __typename?: 'LineStatisticsNotFound';
  message: Scalars['String']['output'];
};

export type ILineStatisticsOutputDto = {
  __typename?: 'LineStatisticsOutputDTO';
  target: Scalars['Float']['output'];
  value: Scalars['Float']['output'];
};

export type ILineWithMetrics = IWithMetrics & {
  __typename?: 'LineWithMetrics';
  factoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  metrics: Array<IKpiMetric>;
  name: Scalars['String']['output'];
  outputFactor: Scalars['Float']['output'];
  shortName: Scalars['String']['output'];
};

export type ILinesReportingFilters = {
  dateRanges: Array<Array<Scalars['Date']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  factoryId: Scalars['ID']['input'];
  filterPeriods: InputMaybe<Array<Array<Scalars['DateTime']['input']>>>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRanges: Array<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type ILinesWithMetrics = IEntitiesWithMetrics & {
  __typename?: 'LinesWithMetrics';
  data: Array<ILineWithMetrics>;
  timeGranularity: ITimeGranularity;
};

export type ILinesWithMetricsResponse = ILinesWithMetrics | IMissingRequiredFields;

export enum IMediumType {
  Email = 'EMAIL',
  InApp = 'IN_APP',
  None = 'NONE'
}

export type IMissingRequiredFields = IUserError & {
  __typename?: 'MissingRequiredFields';
  message: Scalars['String']['output'];
};

export type IMissingUpdateFieldsTag = IUserError & {
  __typename?: 'MissingUpdateFieldsTag';
  message: Scalars['String']['output'];
};

export type IModelForTagDoesNotExist = IUserError & {
  __typename?: 'ModelForTagDoesNotExist';
  message: Scalars['String']['output'];
};

export type IModelIdName = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type IModelIdTags = {
  __typename?: 'ModelIdTags';
  data: Scalars['JSON']['output'];
};

export type IMultiModelTagFilter = {
  AND: InputMaybe<IMultiModelTagFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IMultiModelTagFilter>;
  OR: InputMaybe<IMultiModelTagFilter>;
  modelIdNames: Array<IModelIdName>;
};

export type IMultiModelTagResponse = IMissingRequiredFields | IModelIdTags | IUnauthorizedError | IUnexpectedError;

export type IMutation = {
  __typename?: 'Mutation';
  createAnonymizedVideo: IAnonymizedVideo;
  createAreaGroup: ICreateAreaGroupResponse;
  createCameraBoundingBox: IAreaCreatedResponse;
  createEvent: IEventCreatedResponse;
  createNotificationRule: INotificationRuleCreatedResponse;
  createS3Object: IS3Object;
  createShiftGroup: ICreateShiftGroupResponse;
  createShiftVariant: ICreateShiftVariantResponse;
  createShiftVariantOverride: ICreateShiftVariantOverrideResponse;
  createTag: ICreateTagResponse;
  createVideoBookmark: ICreateAnonymizedVideoBookmarkResponse;
  createWalkingRoutes: IWalkingRouteCreatedResponse;
  deleteAreaGroup: IDeleteAreaGroupResponse;
  deleteBoundingBox: IAreaDeletedResponse;
  deleteNotificationRule: INotificationRuleDeletedResponse;
  deleteShiftGroup: IDeleteShiftGroupResponse;
  deleteShiftVariant: IDeleteShiftVariantResponse;
  deleteTag: IDeleteTagResponse;
  deleteVideoBookmark: IDeleteAnonymizedVideoBookmarkResponse;
  edgeUploadProcessingResults: IUploadProcessingResultsResponse;
  saveUserPreferences: ISaveUserPreferencesResponse;
  tagModel: ITagModelResponse;
  untagModel: IUntagModelResponse;
  updateAnonymizedVideo: IAnonymizedVideo;
  updateAreaGroup: IUpdateAreaGroupResponse;
  updateCameraBoundingBox: IAreaUpdatedResponse;
  updateNotification: INotificationEventUpdateResponse;
  updateNotificationRule: INotificationRuleUpdatedResponse;
  updateShiftGroup: IUpdateShiftGroupResponse;
  updateShiftVariant: IUpdateShiftVariantResponse;
  updateTag: IUpdateTagResponse;
};


export type IMutationCreateAnonymizedVideoArgs = {
  data: IAnonymizedVideoInput;
};


export type IMutationCreateAreaGroupArgs = {
  data: ICreateAreaGroupInput;
};


export type IMutationCreateCameraBoundingBoxArgs = {
  data: ICameraBoundingBoxInput;
};


export type IMutationCreateEventArgs = {
  data: Array<IEventInput>;
};


export type IMutationCreateNotificationRuleArgs = {
  data: INotificationRuleCreateInput;
};


export type IMutationCreateS3ObjectArgs = {
  data: IS3ObjectInput;
};


export type IMutationCreateShiftGroupArgs = {
  data: ICreateShiftGroupInput;
};


export type IMutationCreateShiftVariantArgs = {
  data: ICreateShiftVariantInput;
};


export type IMutationCreateShiftVariantOverrideArgs = {
  data: ICreateShiftVariantOverrideInput;
};


export type IMutationCreateTagArgs = {
  data: ITagInput;
};


export type IMutationCreateVideoBookmarkArgs = {
  data: IAnonymizedVideoIdInput;
};


export type IMutationCreateWalkingRoutesArgs = {
  data: Array<ICreateWalkingRouteInput>;
};


export type IMutationDeleteAreaGroupArgs = {
  data: IAreaGroupIdInput;
};


export type IMutationDeleteBoundingBoxArgs = {
  data: ICameraBoundingBoxDeleteInput;
};


export type IMutationDeleteNotificationRuleArgs = {
  data: INotificationRuleDeleteInput;
};


export type IMutationDeleteShiftGroupArgs = {
  data: IDeleteShiftGroupInput;
};


export type IMutationDeleteShiftVariantArgs = {
  data: IDeleteShiftVariantInput;
};


export type IMutationDeleteTagArgs = {
  data: IDeleteTagInput;
};


export type IMutationDeleteVideoBookmarkArgs = {
  data: IAnonymizedVideoIdInput;
};


export type IMutationEdgeUploadProcessingResultsArgs = {
  data: IUploadProcessingResultsInput;
};


export type IMutationSaveUserPreferencesArgs = {
  data: IUserPreferencesInput;
};


export type IMutationTagModelArgs = {
  data: ITagModelInput;
};


export type IMutationUntagModelArgs = {
  data: IUntagModelInput;
};


export type IMutationUpdateAnonymizedVideoArgs = {
  data: IAnonymizedVideoUpdateInput;
};


export type IMutationUpdateAreaGroupArgs = {
  data: IUpdateAreaGroupInput;
};


export type IMutationUpdateCameraBoundingBoxArgs = {
  data: IUpdateCameraBoundingBoxInput;
};


export type IMutationUpdateNotificationArgs = {
  data: INotificationEventInput;
};


export type IMutationUpdateNotificationRuleArgs = {
  data: INotificationRuleUpdateInput;
};


export type IMutationUpdateShiftGroupArgs = {
  data: IUpdateShiftGroupInput;
};


export type IMutationUpdateShiftVariantArgs = {
  data: IUpdateShiftVariantInput;
};


export type IMutationUpdateTagArgs = {
  data: IUpdateTagInput;
};

export type INotificationEvent = {
  __typename?: 'NotificationEvent';
  id: Scalars['ID']['output'];
  isRead: Scalars['Boolean']['output'];
  notificationRule: INotificationRule;
  observedContext: Scalars['JSON']['output'];
  publishedAt: Scalars['DateTime']['output'];
};

export type INotificationEventFilters = {
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  publishedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type INotificationEventIdFilter = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type INotificationEventInput = {
  id: InputMaybe<Scalars['ID']['input']>;
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt: InputMaybe<Scalars['DateTime']['input']>;
};

export type INotificationEventNotFound = IUserError & {
  __typename?: 'NotificationEventNotFound';
  message: Scalars['String']['output'];
};

export type INotificationEventResponse = {
  __typename?: 'NotificationEventResponse';
  data: Array<INotificationEvent>;
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalCount: Scalars['Int']['output'];
};

export type INotificationEventUpdateResponse = INotificationEvent | INotificationEventNotFound;

export type INotificationRule = {
  __typename?: 'NotificationRule';
  context: INotificationRuleContext;
  id: Scalars['ID']['output'];
  kpiMetric: IKpiMetricType;
  medium: IMediumType;
  relation: IRelationType;
  ruleType: IRuleType;
  station: IStation;
  title: Maybe<Scalars['String']['output']>;
  userUuid: Scalars['UUID']['output'];
};

export type INotificationRuleContext = IINotificationRuleContext & {
  __typename?: 'NotificationRuleContext';
  periodSeconds: Scalars['Int']['output'];
  thresholdType: IThresholdType;
  thresholdValue: Scalars['Int']['output'];
};

export type INotificationRuleContextInput = {
  periodSeconds: Scalars['Int']['input'];
  thresholdType: IThresholdType;
  thresholdValue: Scalars['Int']['input'];
};

export type INotificationRuleCreateInput = {
  context: INotificationRuleContextInput;
  kpiMetric: IKpiMetricType;
  medium: IMediumType;
  relation: IRelationType;
  ruleType: IRuleType;
  station: IStationIdInput;
  title: InputMaybe<Scalars['String']['input']>;
};

export type INotificationRuleCreatedResponse = INotificationRule | IUnexpectedError;

export type INotificationRuleDeleteInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type INotificationRuleDeletedResponse = IDeletedNotificationRuleId | INotificationRuleNotFound | IUnexpectedError;

export type INotificationRuleNotFound = IUserError & {
  __typename?: 'NotificationRuleNotFound';
  message: Scalars['String']['output'];
};

export type INotificationRuleUpdateInput = {
  context: InputMaybe<INotificationRuleContextInput>;
  id: InputMaybe<Scalars['ID']['input']>;
  kpiMetric: InputMaybe<IKpiMetricType>;
  medium: InputMaybe<IMediumType>;
  relation: InputMaybe<IRelationType>;
  station: InputMaybe<IStationIdInput>;
  title: InputMaybe<Scalars['String']['input']>;
};

export type INotificationRuleUpdatedResponse = INotificationRule | INotificationRuleNotFound | IUnexpectedError;

export type INotificationRulesFilter = {
  line: ILineIdInput;
};

export enum IOrdering {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type IQuery = {
  __typename?: 'Query';
  anonymizeCycleSelection: IAnonymizeCycleSelectionResponse;
  anonymizedVideoByPk: Maybe<IAnonymizedVideoWithCycleAndBookmark>;
  anonymizedVideosV2: IAnonymizedVideos;
  areaConnections: IAreaConnectionsResponse;
  areaGroups: Array<IAreaGroup>;
  areaOfInterestsStatistics: IBevStatisticsResponse;
  areaOfInterestsStatisticsV2: Maybe<IAreaOfInterestsStatisticsResponse>;
  cameraBoundingBoxes: Array<ICameraBoundingBox>;
  cameras: Array<ICamera>;
  cycleOverTimeStatistics: ICycleOverTimeResponse;
  cycleStatistics: Array<ICycleStatistics>;
  cycles: Array<ICycle>;
  edgeDevices: IEdgeDevicesResponse;
  factories: Array<IFactory>;
  /** @deprecated Is not used by both new and old app */
  factoriesWithMetrics: IFactoriesWithMetricsResponse;
  getStationCyclesAndEventsData: Maybe<IStationCyclesAndEventsResponse>;
  heatmap: Array<IHeatMap>;
  histogram: Maybe<IStationHistogram>;
  lineAccumulatedByTimeStatistics: Maybe<ILineAccumulatedByTimeStatisticsResponse>;
  lineActivityPerStationStatistics: Maybe<ILineActivityPerStationStatisticsResponse>;
  lineAverageCycleTimeByStation: Maybe<ILineAverageCycleTimeByStationResponse>;
  lineAverageNumberOfWorkers: Maybe<ILineAverageNumberOfWorkersResponse>;
  lineOutputPerStationStatistics: Maybe<ILineOutputPerStationStatisticsResponse>;
  lineOverallStatistics: Maybe<ILineOverallStatisticsResponse>;
  linesWithMetrics: ILinesWithMetricsResponse;
  multimodelTags: IMultiModelTagResponse;
  notificationByPk: Maybe<INotificationEvent>;
  notificationRules: Array<INotificationRule>;
  notifications: INotificationEventResponse;
  organizationalLevels: Array<IFactoryDto>;
  shifts: IShiftGroupResponse;
  stations: Array<IStation>;
  stationsWithMetrics: IStationsWithMetricsResponse;
  tags: Array<ITag>;
  userPreferences: IUserPreferencesResponse;
  walkingRoutes: Array<IWalkingRoute>;
};


export type IQueryAnonymizeCycleSelectionArgs = {
  filters: ICycleFilter;
};


export type IQueryAnonymizedVideoByPkArgs = {
  filters: IAnonymizedVideoIdFilter;
};


export type IQueryAnonymizedVideosV2Args = {
  filters: IAnonymizedVideoFiltersV2;
};


export type IQueryAreaConnectionsArgs = {
  filters: IAreaConnectionsFilter;
};


export type IQueryAreaGroupsArgs = {
  filters: IAreaGroupFilters;
};


export type IQueryAreaOfInterestsStatisticsArgs = {
  filters: IAreaOfInterestFilters;
};


export type IQueryAreaOfInterestsStatisticsV2Args = {
  filters: IAreaOfInterestStatisticsFiltersV2;
};


export type IQueryCameraBoundingBoxesArgs = {
  filters: ICameraBoundingBoxFilter;
};


export type IQueryCamerasArgs = {
  filters?: InputMaybe<ICameraFilter>;
};


export type IQueryCycleOverTimeStatisticsArgs = {
  filters: ICycleStatisticsFilters;
};


export type IQueryCycleStatisticsArgs = {
  filters: ICycleStatisticsFilters;
};


export type IQueryCyclesArgs = {
  filters?: InputMaybe<ICycleFilter>;
};


export type IQueryEdgeDevicesArgs = {
  filters: IEdgeDevicesFilter;
};


export type IQueryFactoriesArgs = {
  filters?: InputMaybe<IFactoryFilter>;
};


export type IQueryFactoriesWithMetricsArgs = {
  filters: IFactoryReportingFilters;
};


export type IQueryGetStationCyclesAndEventsDataArgs = {
  filters: IStationCyclesAndEventsFilters;
};


export type IQueryHeatmapArgs = {
  filters: IHeatMapFilter;
};


export type IQueryHistogramArgs = {
  filters: IHistogramFilters;
};


export type IQueryLineAccumulatedByTimeStatisticsArgs = {
  filters: ILineAccumulatedByTimeStatisticsFilters;
};


export type IQueryLineActivityPerStationStatisticsArgs = {
  filters: ILineActivityPerStationStatisticsFilters;
};


export type IQueryLineAverageCycleTimeByStationArgs = {
  filters: ILineStatisticsFilters;
};


export type IQueryLineAverageNumberOfWorkersArgs = {
  filters: ILineAverageNumberOfWorkersFilters;
};


export type IQueryLineOutputPerStationStatisticsArgs = {
  filters: ILineOutputPerStationStatisticsFilters;
};


export type IQueryLineOverallStatisticsArgs = {
  filters: ILineOverallStatisticsFilters;
};


export type IQueryLinesWithMetricsArgs = {
  filters: ILinesReportingFilters;
};


export type IQueryMultimodelTagsArgs = {
  filters: IMultiModelTagFilter;
};


export type IQueryNotificationByPkArgs = {
  filters: INotificationEventIdFilter;
};


export type IQueryNotificationRulesArgs = {
  filters: INotificationRulesFilter;
};


export type IQueryNotificationsArgs = {
  filters: INotificationEventFilters;
};


export type IQueryShiftsArgs = {
  filters: IShiftFilters;
};


export type IQueryStationsArgs = {
  filters?: InputMaybe<IStationFilter>;
};


export type IQueryStationsWithMetricsArgs = {
  filters: IStationsReportingFilters;
};


export type IQueryTagsArgs = {
  filters: ITagFilter;
};


export type IQueryWalkingRoutesArgs = {
  filters: IWalkingRouteFilter;
};

export enum IRelationType {
  AboveThan = 'ABOVE_THAN',
  BelowThan = 'BELOW_THAN',
  Equals = 'EQUALS'
}

export enum IRuleType {
  Custom = 'CUSTOM',
  NoLineOutput = 'NO_LINE_OUTPUT',
  OutputTargetMet = 'OUTPUT_TARGET_MET',
  OutputTargetNotMet = 'OUTPUT_TARGET_NOT_MET'
}

export type IS3Object = {
  __typename?: 'S3Object';
  bucket: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  key: Scalars['String']['output'];
  metadata: Maybe<Scalars['JSON']['output']>;
  presignedUrl: Scalars['String']['output'];
  region: Scalars['String']['output'];
  uri: Scalars['String']['output'];
};

export type IS3ObjectIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IS3ObjectInput = {
  bucket: Scalars['String']['input'];
  key: Scalars['String']['input'];
  metadata: InputMaybe<Scalars['JSON']['input']>;
  region: InputMaybe<Scalars['String']['input']>;
};

export type ISaveUserPreferencesResponse = IUnexpectedError | IUserPreferences | IUserPreferencesNotFound;

export type IShiftFilters = {
  customer: InputMaybe<ICustomerIdInput>;
  line: ILineIdInput;
};

export type IShiftGroup = {
  __typename?: 'ShiftGroup';
  customer: ICustomer;
  end: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  line: ILine;
  name: Scalars['String']['output'];
  start: Scalars['Time']['output'];
  variants: Array<IShiftVariant>;
};


export type IShiftGroupVariantsArgs = {
  filters?: InputMaybe<IShiftVariantFilter>;
};

export type IShiftGroupIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IShiftGroupNotFound = IUserError & {
  __typename?: 'ShiftGroupNotFound';
  message: Scalars['String']['output'];
};

export type IShiftGroupResponse = IShiftGroups;

export type IShiftGroups = {
  __typename?: 'ShiftGroups';
  data: Array<IShiftGroup>;
};

export type IShiftTarget = IIShiftTarget & {
  __typename?: 'ShiftTarget';
  cycleTime: Scalars['Int']['output'];
  output: Scalars['Int']['output'];
  persons: Scalars['Int']['output'];
};

export type IShiftTargetInput = {
  cycleTime: Scalars['Int']['input'];
  output: Scalars['Int']['input'];
  persons: Scalars['Int']['input'];
};

export type IShiftVariant = {
  __typename?: 'ShiftVariant';
  disabled: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  repeatEvery: Scalars['Int']['output'];
  repeatInterval: Scalars['String']['output'];
  repeatStart: Scalars['Date']['output'];
  shiftGroup: IShiftGroup;
  weekdaysTargets: Array<Maybe<IShiftTarget>>;
  workingTimes: Array<Array<Scalars['Time']['output']>>;
};

export type IShiftVariantFilter = {
  AND: InputMaybe<IShiftVariantFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IShiftVariantFilter>;
  OR: InputMaybe<IShiftVariantFilter>;
  applicableDate: InputMaybe<Scalars['Date']['input']>;
};

export type IShiftVariantNotFound = IUserError & {
  __typename?: 'ShiftVariantNotFound';
  message: Scalars['String']['output'];
};

export type IStation = {
  __typename?: 'Station';
  cycles: Array<ICycle>;
  deactivated: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isOutput: Scalars['Boolean']['output'];
  line: ILine;
  name: Scalars['String']['output'];
  orderWeight: Scalars['Int']['output'];
  shortName: Scalars['String']['output'];
  type: Scalars['Int']['output'];
};


export type IStationCyclesArgs = {
  filters: InputMaybe<ICycleFilter>;
};

export type IStationActivityDto = {
  __typename?: 'StationActivityDTO';
  data: Array<IVariantActivityDto>;
  stationId: Scalars['ID']['output'];
};

export type IStationAverageCycleTimeDto = {
  __typename?: 'StationAverageCycleTimeDTO';
  compared: Array<IKeyValueData>;
  single: Array<IKeyValueData>;
  stationId: Scalars['ID']['output'];
};

export type IStationCyclesAndEventsDto = {
  __typename?: 'StationCyclesAndEventsDTO';
  cycles: Array<ICycleDto>;
  events: Array<IEventDto>;
  timeWindows: Array<ITimeWindowDto>;
};

export type IStationCyclesAndEventsFilters = {
  stationId: Scalars['ID']['input'];
  timestampEnd: Scalars['DateTime']['input'];
  timestampStart: Scalars['DateTime']['input'];
};

export type IStationCyclesAndEventsResponse = IStationCyclesAndEventsDto | IStationStatisticsNotFound;

export type IStationDto = {
  __typename?: 'StationDTO';
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isOutput: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  type: IStationTypes;
};

export type IStationFilter = {
  AND: InputMaybe<IStationFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IStationFilter>;
  OR: InputMaybe<IStationFilter>;
  deactivated: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<IIdFilterLookup>;
  limit: InputMaybe<Scalars['Int']['input']>;
  line: InputMaybe<ILineFilter>;
  orderBy: InputMaybe<Array<Scalars['String']['input']>>;
};

export type IStationHistogram = {
  __typename?: 'StationHistogram';
  cycleCount: Scalars['Float']['output'];
  data: Array<IHistogramBin>;
  duration: ICycleDurationStatistics;
  durationTarget: IAvgTotalStatistics;
};

export type IStationIdInput = {
  id: InputMaybe<Scalars['ID']['input']>;
};

export type IStationNotFound = IUserError & {
  __typename?: 'StationNotFound';
  message: Scalars['String']['output'];
};

export type IStationOutputDto = {
  __typename?: 'StationOutputDTO';
  combined: Array<IKeyValueData>;
  compared: Array<IKeyValueData>;
  single: Array<IKeyValueData>;
  stationId: Scalars['ID']['output'];
};

export type IStationStatisticsNotFound = IUserError & {
  __typename?: 'StationStatisticsNotFound';
  message: Scalars['String']['output'];
};

export enum IStationTypes {
  Machine = 'MACHINE',
  Manual = 'MANUAL'
}

export type IStationWithMetrics = IWithMetrics & {
  __typename?: 'StationWithMetrics';
  factoryId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  lineId: Scalars['ID']['output'];
  metrics: Array<IKpiMetric>;
  name: Scalars['String']['output'];
  outputFactor: Scalars['Float']['output'];
  shortName: Scalars['String']['output'];
};

export type IStationsReportingFilters = {
  dateRanges: Array<Array<Scalars['Date']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  filterPeriods: InputMaybe<Array<Array<Scalars['DateTime']['input']>>>;
  lineId: Scalars['ID']['input'];
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRanges: Array<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IStationsWithMetrics = IEntitiesWithMetrics & {
  __typename?: 'StationsWithMetrics';
  data: Array<IStationWithMetrics>;
  timeGranularity: ITimeGranularity;
};

export type IStationsWithMetricsResponse = IMissingRequiredFields | IStationsWithMetrics;

export type IStrFilterLookup = {
  contains: InputMaybe<Scalars['String']['input']>;
  endsWith: InputMaybe<Scalars['String']['input']>;
  exact: InputMaybe<Scalars['String']['input']>;
  gt: InputMaybe<Scalars['String']['input']>;
  gte: InputMaybe<Scalars['String']['input']>;
  iContains: InputMaybe<Scalars['String']['input']>;
  iEndsWith: InputMaybe<Scalars['String']['input']>;
  iExact: InputMaybe<Scalars['String']['input']>;
  iRegex: InputMaybe<Scalars['String']['input']>;
  iStartsWith: InputMaybe<Scalars['String']['input']>;
  inList: InputMaybe<Array<Scalars['String']['input']>>;
  isNull: InputMaybe<Scalars['Boolean']['input']>;
  lt: InputMaybe<Scalars['String']['input']>;
  lte: InputMaybe<Scalars['String']['input']>;
  range: InputMaybe<Array<Scalars['String']['input']>>;
  regex: InputMaybe<Scalars['String']['input']>;
  startsWith: InputMaybe<Scalars['String']['input']>;
};

export type ISystemError = {
  message: Scalars['String']['output'];
};

export type ITag = {
  __typename?: 'Tag';
  color: Array<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  tagType: Scalars['Int']['output'];
  taggables: Array<ITagTaggable>;
};

export type ITagFilter = {
  AND: InputMaybe<ITagFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ITagFilter>;
  OR: InputMaybe<ITagFilter>;
  id: InputMaybe<IIdFilterLookup>;
  name: InputMaybe<IStrFilterLookup>;
  tagType: InputMaybe<Scalars['Int']['input']>;
  taggables: ITagTaggableFilter;
};

export type ITagInput = {
  color: InputMaybe<Array<Scalars['Int']['input']>>;
  name: Scalars['String']['input'];
  tagType: Scalars['Int']['input'];
};

export type ITagModelInput = {
  tagId: Scalars['ID']['input'];
  taggedModelId: Scalars['ID']['input'];
  taggedModelType: Scalars['String']['input'];
};

export type ITagModelResponse = IModelForTagDoesNotExist | ITagTaggable | ITaggedModelDoesNotExist | IUnexpectedError;

export type ITagTaggable = {
  __typename?: 'TagTaggable';
  id: Scalars['ID']['output'];
  modelId: Scalars['ID']['output'];
  modelName: Scalars['String']['output'];
  tag: ITag;
};

export type ITagTaggableFilter = {
  AND: InputMaybe<ITagTaggableFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<ITagTaggableFilter>;
  OR: InputMaybe<ITagTaggableFilter>;
  modelId: InputMaybe<IIntFilterLookup>;
  modelName: InputMaybe<IStrFilterLookup>;
};

export type ITaggedModelDoesNotExist = IUserError & {
  __typename?: 'TaggedModelDoesNotExist';
  message: Scalars['String']['output'];
};

export enum IThresholdType {
  Absolute = 'ABSOLUTE',
  PercentToTarget = 'PERCENT_TO_TARGET'
}

export enum ITimeGranularity {
  Day = 'DAY',
  Hour = 'HOUR',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Week = 'WEEK'
}

export type ITimeWindowDto = {
  __typename?: 'TimeWindowDTO';
  endTime: Scalars['DateTime']['output'];
  startTime: Scalars['DateTime']['output'];
  type: Scalars['String']['output'];
  variantId: Scalars['ID']['output'];
};

export type IUnauthorizedError = IUserError & {
  __typename?: 'UnauthorizedError';
  message: Scalars['String']['output'];
};

export type IUnexpectedError = ISystemError & {
  __typename?: 'UnexpectedError';
  message: Scalars['String']['output'];
};

export type IUntagModelId = {
  __typename?: 'UntagModelId';
  modelId: Scalars['ID']['output'];
};

export type IUntagModelInput = {
  modelId: Scalars['ID']['input'];
  modelName: Scalars['String']['input'];
  tagId: Scalars['ID']['input'];
};

export type IUntagModelResponse = IUnexpectedError | IUntagModelId;

export type IUpdateAreaGroupInput = {
  color: Array<Scalars['Int']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
};

export type IUpdateAreaGroupResponse = IAreaGroup | IAreaGroupNotFound | IInvalidGroupColor | IInvalidGroupName;

export type IUpdateCameraBoundingBoxInput = {
  camera: ICameraIdInput;
  coordinates: Scalars['JSON']['input'];
  group: IAreaGroupIdInput;
  id: InputMaybe<Scalars['ID']['input']>;
  station: InputMaybe<IStationIdInput>;
  virtualBbox: InputMaybe<ICameraBoundingBoxIdInput>;
};

export type IUpdateShiftGroupInput = {
  end: InputMaybe<Scalars['String']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  start: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateShiftGroupResponse = IInvalidGroupName | IInvalidTimes | IShiftGroup | IShiftGroupNotFound;

export type IUpdateShiftVariantInput = {
  appliedAt: Scalars['Date']['input'];
  disabled: InputMaybe<Scalars['Boolean']['input']>;
  id: InputMaybe<Scalars['ID']['input']>;
  name: InputMaybe<Scalars['String']['input']>;
  repeatEvery: InputMaybe<Scalars['Int']['input']>;
  repeatInterval: InputMaybe<Scalars['String']['input']>;
  repeatStart: InputMaybe<Scalars['Date']['input']>;
  weekdaysTargets: InputMaybe<Array<InputMaybe<IShiftTargetInput>>>;
  workingTimes: InputMaybe<Array<Array<Scalars['Time']['input']>>>;
};

export type IUpdateShiftVariantResponse = IInvalidGroupName | IInvalidShiftVariant | IShiftVariant | IShiftVariantNotFound;

export type IUpdateTagInput = {
  color: InputMaybe<Array<Scalars['Int']['input']>>;
  id: Scalars['ID']['input'];
  name: InputMaybe<Scalars['String']['input']>;
};

export type IUpdateTagResponse = IMissingUpdateFieldsTag | ITag | IUnexpectedError;

export type IUploadProcessingResultsInput = {
  device: IEdgeDeviceInput;
  payload: Scalars['String']['input'];
};

export type IUploadProcessingResultsResponse = IAreaNotFound | IBoundingBoxAndCameraStationBothNull | ICreateWalkingRouteFailure | IEdgeDeviceNotFound | IEventsBatchResponse | IInvalidDateTimeRange | IInvalidPath | IInvalidPayloadContent | IMissingRequiredFields | IWalkingRoutesBatchResponse;

export type IUserError = {
  message: Scalars['String']['output'];
};

export type IUserPreferences = {
  __typename?: 'UserPreferences';
  onboardingGuides: Scalars['JSON']['output'];
  userId: Scalars['UUID']['output'];
};

export type IUserPreferencesInput = {
  onboardingGuides: Scalars['JSON']['input'];
};

export type IUserPreferencesNotFound = IUserError & {
  __typename?: 'UserPreferencesNotFound';
  message: Scalars['String']['output'];
};

export type IUserPreferencesResponse = IUnexpectedError | IUserPreferences;

export type IVariantActivityDto = {
  __typename?: 'VariantActivityDTO';
  data: Array<IKeyValueData>;
  tagId: Scalars['ID']['output'];
};

export type IVirtualSensorToCamera = {
  __typename?: 'VirtualSensorToCamera';
  camera: ICamera;
  coordinates: Scalars['JSON']['output'];
  virtualSensor: ICamera;
};

export type IWalkingRoute = {
  __typename?: 'WalkingRoute';
  camera: ICamera;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  path: Array<Array<Scalars['Int']['output']>>;
  routeId: Scalars['String']['output'];
  timeRange: Array<Scalars['DateTime']['output']>;
};

export type IWalkingRouteCreatedResponse = IAreaNotFound | ICreateWalkingRouteFailure | IInvalidDateTimeRange | IInvalidPath | IMissingRequiredFields | IWalkingRoutesBatchResponse;

export type IWalkingRouteFilter = {
  AND: InputMaybe<IWalkingRouteFilter>;
  DISTINCT: InputMaybe<Scalars['Boolean']['input']>;
  NOT: InputMaybe<IWalkingRouteFilter>;
  OR: InputMaybe<IWalkingRouteFilter>;
  camera: ICameraFilter;
  dateRange: InputMaybe<Array<Scalars['Date']['input']>>;
  exludedWeekdays: InputMaybe<Array<Scalars['Int']['input']>>;
  limit: InputMaybe<Scalars['Int']['input']>;
  shiftGroupIds: InputMaybe<Array<Scalars['ID']['input']>>;
  timeRange: InputMaybe<Array<Scalars['Time']['input']>>;
  timezone: InputMaybe<Scalars['String']['input']>;
};

export type IWalkingRouteNoId = {
  __typename?: 'WalkingRouteNoId';
  camera: ICamera;
  createdAt: Scalars['DateTime']['output'];
  path: Array<Array<Scalars['Int']['output']>>;
  routeId: Scalars['String']['output'];
  timeRange: Array<Scalars['DateTime']['output']>;
};

export type IWalkingRoutesBatchResponse = {
  __typename?: 'WalkingRoutesBatchResponse';
  data: Array<IWalkingRouteNoId>;
  failureReasons: Array<Scalars['String']['output']>;
  failures: Scalars['Int']['output'];
  inserts: Scalars['Int']['output'];
};

export type IWithMetrics = {
  id: Scalars['ID']['output'];
  metrics: Array<IKpiMetric>;
  name: Scalars['String']['output'];
  outputFactor: Scalars['Float']['output'];
  shortName: Scalars['String']['output'];
};

export type IGetAreaGroupsByTypeQueryVariables = Exact<{
  groupType: Scalars['Int']['input'];
  customerId: Scalars['ID']['input'];
}>;


export type IGetAreaGroupsByTypeQuery = { __typename?: 'Query', areaGroups: Array<{ __typename?: 'AreaGroup', id: string, name: string, color: Array<number>, groupType: number }> };

export type ICreateAreaGroupMutationVariables = Exact<{
  customerId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  color: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type ICreateAreaGroupMutation = { __typename?: 'Mutation', createAreaGroup: { __typename: 'AreaGroup', id: string } | { __typename: 'CustomerNotFound', message: string } | { __typename: 'InvalidGroupColor', message: string } | { __typename: 'InvalidGroupName', message: string } };

export type IUpdateAreaGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  color: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type IUpdateAreaGroupMutation = { __typename?: 'Mutation', updateAreaGroup: { __typename: 'AreaGroup', id: string } | { __typename: 'AreaGroupNotFound', message: string } | { __typename: 'InvalidGroupColor', message: string } | { __typename: 'InvalidGroupName', message: string } };

export type IDeleteAreaGroupMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDeleteAreaGroupMutation = { __typename?: 'Mutation', deleteAreaGroup: { __typename: 'AreaGroupNotFound', message: string } | { __typename: 'DeletedAreaGroupId', id: string } };

export type IGetVirtualSensorAreasLayoutByLineIdQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
}>;


export type IGetVirtualSensorAreasLayoutByLineIdQuery = { __typename?: 'Query', cameras: Array<{ __typename?: 'Camera', id: string, resWidth: number, resHeight: number, areas: Array<{ __typename?: 'CameraBoundingBox', id: string, coordinates: any, group: { __typename?: 'AreaGroup', id: string, name: string, color: Array<number>, groupType: number }, virtualBbox: { __typename?: 'CameraBoundingBox', id: string } | null, downstreamAreas: Array<{ __typename?: 'CameraBoundingBox', id: string, cameraStation: { __typename?: 'CameraStation', station: { __typename?: 'Station', id: string, name: string, deactivated: boolean } } | null }> }> }> };

export type IGetAreaConnectionsQueryVariables = Exact<{
  fromArea: Scalars['ID']['input'];
  dateRanges: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetAreaConnectionsQuery = { __typename?: 'Query', areaConnections: { __typename?: 'AreaConnectionsResponse', connections: Array<{ __typename?: 'Connection', count: number, cameraBoundingBox: { __typename?: 'CameraBoundingBox', id: string } }> } };

export type IGetVirtualSensorImageByLineIdQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
}>;


export type IGetVirtualSensorImageByLineIdQuery = { __typename?: 'Query', cameras: Array<{ __typename?: 'Camera', id: string, resWidth: number, resHeight: number, latestSnapshot: { __typename?: 'S3Object', presignedUrl: string } }> };

export type IGetAreaOfInterestsStatisticsQueryVariables = Exact<{
  boxId: Scalars['ID']['input'];
  dateRanges: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;


export type IGetAreaOfInterestsStatisticsQuery = { __typename?: 'Query', areaOfInterestsStatistics: { __typename: 'AreaOfInterestStatisticsDTO', timeSpentRatio: number, avgTimeSpent: Array<{ __typename?: 'AreaAvgTimeSpent', time: any, durationSeconds: number }>, avgWorkerCount: Array<{ __typename?: 'AreaAvgWorkersCount', time: any, workerCount: number, timeSpentRatio: number }> } | { __typename: 'MissingRequiredFields', message: string } | null };

export type IGetHeatmapByCameraIdQueryVariables = Exact<{
  cameraId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetHeatmapByCameraIdQuery = { __typename?: 'Query', heatmap: Array<{ __typename?: 'HeatMap', heatmap: any }> };

export type IGetAreasOfInterestByCameraIdQueryVariables = Exact<{
  cameraId: Scalars['ID']['input'];
}>;


export type IGetAreasOfInterestByCameraIdQuery = { __typename?: 'Query', cameraBoundingBoxes: Array<{ __typename?: 'CameraBoundingBox', id: string, coordinates: any, camera: { __typename?: 'DjangoModelType', pk: string }, virtualBbox: { __typename?: 'CameraBoundingBox', id: string } | null, downstreamAreas: Array<{ __typename?: 'CameraBoundingBox', id: string }>, cameraStation: { __typename?: 'CameraStation', id: string, station: { __typename?: 'Station', id: string, name: string } } | null, group: { __typename?: 'AreaGroup', id: string, name: string, color: Array<number>, groupType: number } }> };

export type ICreateAreaOfInterestMutationVariables = Exact<{
  cameraId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  coordinates: Scalars['JSON']['input'];
}>;


export type ICreateAreaOfInterestMutation = { __typename?: 'Mutation', createCameraBoundingBox: { __typename: 'AreaHasOverlap', message: string } | { __typename: 'CameraBoundingBox', id: string } | { __typename: 'CameraNotFound', message: string } | { __typename: 'DifferentGroupType', message: string } | { __typename: 'GroupNotFound', message: string } | { __typename: 'InvalidCameraBoundingBoxCoordinates', message: string } | { __typename: 'MissingRequiredFields', message: string } | { __typename: 'StationNotFound', message: string } };

export type IUpdateAreaOfInterestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  cameraId: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  coordinates: Scalars['JSON']['input'];
  stationId: InputMaybe<Scalars['ID']['input']>;
  virtualBoundingBoxId: InputMaybe<Scalars['ID']['input']>;
}>;


export type IUpdateAreaOfInterestMutation = { __typename?: 'Mutation', updateCameraBoundingBox: { __typename: 'AreaHasOverlap', message: string } | { __typename: 'AreaNotFound', message: string } | { __typename: 'CameraBoundingBox', id: string } | { __typename: 'CameraNotFound', message: string } | { __typename: 'DifferentGroupType', message: string } | { __typename: 'GroupNotFound', message: string } | { __typename: 'InvalidCameraBoundingBoxCoordinates', message: string } | { __typename: 'MissingRequiredFields', message: string } | { __typename: 'StationNotFound', message: string } };

export type IDeleteAreaOfInterestMutationVariables = Exact<{
  id: Scalars['ID']['input'];
}>;


export type IDeleteAreaOfInterestMutation = { __typename?: 'Mutation', deleteBoundingBox: { __typename: 'AreaNotFound', message: string } | { __typename: 'DeletedCameraBoundingBox', id: string } };

export type IGetCamerasByLineIdQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
}>;


export type IGetCamerasByLineIdQuery = { __typename?: 'Query', cameras: Array<{ __typename?: 'Camera', id: string, camType: number, resWidth: number, resHeight: number, kinesisVideoStream: { __typename?: 'KinesisVideoStream', name: string, arn: string } | null, latestSnapshot: { __typename?: 'S3Object', presignedUrl: string }, areas: Array<{ __typename?: 'CameraBoundingBox', id: string, coordinates: any, group: { __typename?: 'AreaGroup', id: string, color: Array<number>, name: string, groupType: number } }> }> };

export type IGetStationCyclesAndEventsQueryVariables = Exact<{
  timestampStart: Scalars['DateTime']['input'];
  timestampEnd: Scalars['DateTime']['input'];
  stationId: Scalars['ID']['input'];
}>;


export type IGetStationCyclesAndEventsQuery = { __typename?: 'Query', getStationCyclesAndEventsData: { __typename: 'StationCyclesAndEventsDTO', cycles: Array<{ __typename?: 'CycleDTO', cycleId: string, variantId: string, type: string, timestampStart: any, timestampEnd: any, videoId: string | null }>, events: Array<{ __typename?: 'EventDTO', id: string, type: string, timestamp: any, metadata: any, areaId: string, stationId: string, cameraId: string }>, timeWindows: Array<{ __typename?: 'TimeWindowDTO', variantId: string, type: string, startTime: any, endTime: any }> } | { __typename: 'StationStatisticsNotFound', message: string } | null };

export type ILineFragmentFragment = { __typename?: 'LinesWithMetrics', data: Array<{ __typename?: 'LineWithMetrics', id: string, name: string, shortName: string, factoryId: string, metrics: Array<{ __typename?: 'KpiMetric', type: IKpiType, target: number, average: number, values: Array<{ __typename?: 'KpiMetricValue', type: IKpiValueType, value: number, overTime: Array<{ __typename?: 'KpiDataPoint', date: any, value: number }> }> }> }> };

export type IGetLinesWithMetricsQueryVariables = Exact<{
  factoryId: Scalars['ID']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRanges: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  timeRanges: Array<Array<Scalars['Time']['input']> | Scalars['Time']['input']> | Array<Scalars['Time']['input']> | Scalars['Time']['input'];
}>;


export type IGetLinesWithMetricsQuery = { __typename?: 'Query', linesWithMetrics: { __typename: 'LinesWithMetrics', timeGranularity: ITimeGranularity, data: Array<{ __typename?: 'LineWithMetrics', id: string, name: string, shortName: string, factoryId: string, metrics: Array<{ __typename?: 'KpiMetric', type: IKpiType, target: number, average: number, values: Array<{ __typename?: 'KpiMetricValue', type: IKpiValueType, value: number, overTime: Array<{ __typename?: 'KpiDataPoint', date: any, value: number }> }> }> }> } | { __typename: 'MissingRequiredFields', message: string } };

export type IGetLineOverallStatisticsQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  stationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineOverallStatisticsQuery = { __typename?: 'Query', lineOverallStatistics: { __typename: 'LineOverallStatisticsDTO', activeTimePercentage: number, output: { __typename?: 'LineStatisticsOutputDTO', value: number, target: number }, meanCycleTime: { __typename?: 'LineStatisticsOutputDTO', value: number, target: number } } | { __typename: 'LineStatisticsNotFound', message: string } | null };

export type IGetLineAverageNumberOfWorkersQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  stationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineAverageNumberOfWorkersQuery = { __typename?: 'Query', lineAverageNumberOfWorkers: { __typename: 'LineAverageNumberOfWorkersDTO', value: number, target: number } | { __typename: 'LineStatisticsNotFound', message: string } | null };

export type IGetLineStatisticsAccumulatedByTimeQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  excludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineStatisticsAccumulatedByTimeQuery = { __typename?: 'Query', lineAccumulatedByTimeStatistics: { __typename: 'LineAccumulatedByTimeStatisticsDTO', timeGranularity: ITimeGranularity, workingHours: Array<Array<any>> | null, data: Array<{ __typename?: 'CycleCountByTimeDataPointDTO', datetime: any, aggregationCount: number, aggregationTarget: number }> } | { __typename: 'LineStatisticsNotFound', message: string } | { __typename: 'MissingRequiredFields', message: string } | null };

export type IGetLineOutputPerStationStatisticsQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  excludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineOutputPerStationStatisticsQuery = { __typename?: 'Query', lineOutputPerStationStatistics: { __typename: 'LineOutputPerStationStatisticsDTO', data: Array<{ __typename?: 'StationOutputDTO', stationId: string, single: Array<{ __typename?: 'KeyValueData', key: string, value: number }>, combined: Array<{ __typename?: 'KeyValueData', key: string, value: number }>, compared: Array<{ __typename?: 'KeyValueData', key: string, value: number }> }> } | { __typename: 'LineStatisticsNotFound', message: string } | null };

export type IGetLineAverageCycleTimeByStationQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  excludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  productIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineAverageCycleTimeByStationQuery = { __typename?: 'Query', lineAverageCycleTimeByStation: { __typename: 'LineAverageCycleTimeByStationDTO', taktTime: number, data: Array<{ __typename?: 'StationAverageCycleTimeDTO', stationId: string, single: Array<{ __typename?: 'KeyValueData', key: string, value: number }>, compared: Array<{ __typename?: 'KeyValueData', key: string, value: number }> }> } | { __typename: 'LineStatisticsNotFound', message: string } | null };

export type IGetLineActivityPerStationStatisticsQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  excludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetLineActivityPerStationStatisticsQuery = { __typename?: 'Query', lineActivityPerStationStatistics: { __typename: 'LineActivityPerStationStatisticsDTO', data: Array<{ __typename?: 'StationActivityDTO', stationId: string, data: Array<{ __typename?: 'VariantActivityDTO', tagId: string, data: Array<{ __typename?: 'KeyValueData', key: string, value: number }> }> }> } | { __typename: 'LineStatisticsNotFound', message: string } | null };

export type IGetNotificationsQueryVariables = Exact<{
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt: Scalars['DateTime']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
}>;


export type IGetNotificationsQuery = { __typename?: 'Query', notifications: { __typename?: 'NotificationEventResponse', totalCount: number, limit: number, offset: number, data: Array<{ __typename?: 'NotificationEvent', id: string, publishedAt: any, isRead: boolean, notificationRule: { __typename?: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } } }> } };

export type IGetNotificationByPkQueryVariables = Exact<{
  notificationId: Scalars['ID']['input'];
}>;


export type IGetNotificationByPkQuery = { __typename?: 'Query', notificationByPk: { __typename?: 'NotificationEvent', id: string, publishedAt: any, isRead: boolean, notificationRule: { __typename?: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } } } | null };

export type IUpdateNotificationMutationVariables = Exact<{
  notificationId: Scalars['ID']['input'];
  isRead: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt: InputMaybe<Scalars['DateTime']['input']>;
}>;


export type IUpdateNotificationMutation = { __typename?: 'Mutation', updateNotification: { __typename: 'NotificationEvent', id: string, publishedAt: any, isRead: boolean, notificationRule: { __typename?: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } } } | { __typename: 'NotificationEventNotFound', message: string } };

export type IGetNotificationRulesQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
}>;


export type IGetNotificationRulesQuery = { __typename?: 'Query', notificationRules: Array<{ __typename?: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } }> };

export type ICreateNotificationRuleMutationVariables = Exact<{
  data: INotificationRuleCreateInput;
}>;


export type ICreateNotificationRuleMutation = { __typename?: 'Mutation', createNotificationRule: { __typename: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } } | { __typename: 'UnexpectedError', message: string } };

export type IUpdateNotificationRuleMutationVariables = Exact<{
  data: INotificationRuleUpdateInput;
}>;


export type IUpdateNotificationRuleMutation = { __typename?: 'Mutation', updateNotificationRule: { __typename: 'NotificationRule', id: string, title: string | null, ruleType: IRuleType, kpiMetric: IKpiMetricType, relation: IRelationType, medium: IMediumType, station: { __typename?: 'Station', id: string }, context: { __typename?: 'NotificationRuleContext', periodSeconds: number, thresholdType: IThresholdType, thresholdValue: number } } | { __typename: 'NotificationRuleNotFound', message: string } | { __typename: 'UnexpectedError', message: string } };

export type IDeleteNotificationRuleMutationVariables = Exact<{
  data: INotificationRuleDeleteInput;
}>;


export type IDeleteNotificationRuleMutation = { __typename?: 'Mutation', deleteNotificationRule: { __typename: 'DeletedNotificationRuleId', id: string } | { __typename: 'NotificationRuleNotFound', message: string } | { __typename: 'UnexpectedError', message: string } };

export type IGetFactoriesWithLinesAndStationsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetFactoriesWithLinesAndStationsQuery = { __typename?: 'Query', organizationalLevels: Array<{ __typename?: 'FactoryDTO', id: string, name: string, customerId: string, lines: Array<{ __typename?: 'LineDTO', id: string, name: string, inTraining: boolean, shifts: Array<{ __typename?: 'LineShift', id: string, name: string, start: any, end: any }>, stations: Array<{ __typename?: 'StationDTO', id: string, name: string, isOutput: boolean, type: IStationTypes }> }> }> };

export type IGetShiftsQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  applicableDate: InputMaybe<Scalars['Date']['input']>;
}>;


export type IGetShiftsQuery = { __typename?: 'Query', shifts: { __typename: 'ShiftGroups', data: Array<{ __typename?: 'ShiftGroup', id: string, start: any, end: any, name: string, variants: Array<{ __typename?: 'ShiftVariant', id: string, name: string, disabled: boolean, repeatStart: any, repeatEvery: number, repeatInterval: string, workingTimes: Array<Array<any>>, weekdaysTargets: Array<{ __typename?: 'ShiftTarget', output: number, cycleTime: number, persons: number } | null> }> }> } };

export type ICreateShiftVariantMutationVariables = Exact<{
  input: ICreateShiftVariantInput;
}>;


export type ICreateShiftVariantMutation = { __typename?: 'Mutation', createShiftVariant: { __typename: 'InvalidGroupName', message: string } | { __typename: 'InvalidShiftVariant', message: string } | { __typename: 'ShiftGroupNotFound', message: string } | { __typename: 'ShiftVariant', id: string, name: string, disabled: boolean, repeatStart: any, repeatEvery: number, repeatInterval: string, workingTimes: Array<Array<any>>, shiftGroup: { __typename?: 'ShiftGroup', id: string }, weekdaysTargets: Array<{ __typename?: 'ShiftTarget', output: number, cycleTime: number, persons: number } | null> } };

export type IUpdateShiftVariantMutationVariables = Exact<{
  input: IUpdateShiftVariantInput;
}>;


export type IUpdateShiftVariantMutation = { __typename?: 'Mutation', updateShiftVariant: { __typename: 'InvalidGroupName', message: string } | { __typename: 'InvalidShiftVariant', message: string } | { __typename: 'ShiftVariant', id: string, name: string, disabled: boolean, repeatStart: any, repeatEvery: number, repeatInterval: string, workingTimes: Array<Array<any>>, shiftGroup: { __typename?: 'ShiftGroup', id: string }, weekdaysTargets: Array<{ __typename?: 'ShiftTarget', output: number, cycleTime: number, persons: number } | null> } | { __typename: 'ShiftVariantNotFound', message: string } };

export type ICreateShiftVariantOverrideMutationVariables = Exact<{
  input: ICreateShiftVariantOverrideInput;
}>;


export type ICreateShiftVariantOverrideMutation = { __typename?: 'Mutation', createShiftVariantOverride: { __typename: 'CreatedShiftVariantOverrideId', id: string } | { __typename: 'InvalidShiftVariant', message: string } | { __typename: 'ShiftVariantNotFound', message: string } };

export type IDeleteShiftVariantMutationVariables = Exact<{
  input: IDeleteShiftVariantInput;
}>;


export type IDeleteShiftVariantMutation = { __typename?: 'Mutation', deleteShiftVariant: { __typename: 'DeletedShiftVariantId', id: string } | { __typename: 'ShiftVariantNotFound', message: string } };

export type IGetStationStatisticsQueryVariables = Exact<{
  stationId: Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;


export type IGetStationStatisticsQuery = { __typename?: 'Query', histogram: { __typename: 'StationHistogram', cycleCount: number, duration: { __typename?: 'CycleDurationStatistics', median: number, mean: number, variance: number }, durationTarget: { __typename?: 'AvgTotalStatistics', total: number, mean: number }, data: Array<{ __typename?: 'HistogramBin', range: Array<number>, cycleCount: number }> } | null, cycleOverTimeStatistics: { __typename: 'CycleOverTimeStatistics', timeGranularity: ITimeGranularity, data: Array<{ __typename?: 'CycleOverTimePoint', datetime: any, count: number, totalDuration: number }> } | { __typename: 'MissingRequiredFields', message: string } };

export type IStationFragmentFragment = { __typename?: 'StationsWithMetrics', data: Array<{ __typename?: 'StationWithMetrics', id: string, name: string, shortName: string, lineId: string, factoryId: string, metrics: Array<{ __typename?: 'KpiMetric', type: IKpiType, target: number, average: number, values: Array<{ __typename?: 'KpiMetricValue', type: IKpiValueType, value: number, overTime: Array<{ __typename?: 'KpiDataPoint', date: any, value: number }> }> }> }> };

export type IGetStationsWithMetricsQueryVariables = Exact<{
  lineId: Scalars['ID']['input'];
  exludedWeekdays: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
  dateRanges: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  timeRanges: Array<Array<Scalars['Time']['input']> | Scalars['Time']['input']> | Array<Scalars['Time']['input']> | Scalars['Time']['input'];
}>;


export type IGetStationsWithMetricsQuery = { __typename?: 'Query', stationsWithMetrics: { __typename: 'MissingRequiredFields', message: string } | { __typename: 'StationsWithMetrics', timeGranularity: ITimeGranularity, data: Array<{ __typename?: 'StationWithMetrics', id: string, name: string, shortName: string, lineId: string, factoryId: string, metrics: Array<{ __typename?: 'KpiMetric', type: IKpiType, target: number, average: number, values: Array<{ __typename?: 'KpiMetricValue', type: IKpiValueType, value: number, overTime: Array<{ __typename?: 'KpiDataPoint', date: any, value: number }> }> }> }> } };

export type IGetAllTagsQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetAllTagsQuery = { __typename?: 'Query', tags: Array<{ __typename?: 'Tag', id: string, name: string, color: Array<number>, tagType: number }> };

export type ICreateTagMutationVariables = Exact<{
  name: Scalars['String']['input'];
}>;


export type ICreateTagMutation = { __typename?: 'Mutation', createTag: { __typename: 'Tag', id: string, name: string, color: Array<number> } | { __typename: 'UnauthorizedError', message: string } | { __typename: 'UnexpectedError', message: string } };

export type ITagVideoMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
  taggedModelId: Scalars['ID']['input'];
}>;


export type ITagVideoMutation = { __typename?: 'Mutation', tagModel: { __typename: 'ModelForTagDoesNotExist', message: string } | { __typename: 'TagTaggable', id: string, modelId: string, modelName: string, tag: { __typename?: 'Tag', id: string, name: string, color: Array<number> } } | { __typename: 'TaggedModelDoesNotExist', message: string } | { __typename: 'UnexpectedError', message: string } };

export type IUntagVideoMutationVariables = Exact<{
  tagId: Scalars['ID']['input'];
  modelId: Scalars['ID']['input'];
}>;


export type IUntagVideoMutation = { __typename?: 'Mutation', untagModel: { __typename: 'UnexpectedError', message: string } | { __typename: 'UntagModelId', modelId: string } };

export type IGetUserPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type IGetUserPreferencesQuery = { __typename?: 'Query', userPreferences: { __typename: 'UnexpectedError', message: string } | { __typename: 'UserPreferences', userId: any, onboardingGuides: any } };

export type ISaveUserPreferencesMutationVariables = Exact<{
  onboardingGuides: Scalars['JSON']['input'];
}>;


export type ISaveUserPreferencesMutation = { __typename?: 'Mutation', saveUserPreferences: { __typename: 'UnexpectedError', message: string } | { __typename: 'UserPreferences', userId: any, onboardingGuides: any } | { __typename: 'UserPreferencesNotFound', message: string } };

export type IGetAnonymizedVideosQueryVariables = Exact<{
  stationIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  tagIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  dateRange: Array<Scalars['Date']['input']> | Scalars['Date']['input'];
  shiftGroupIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  durationRange: InputMaybe<Array<Scalars['Float']['input']> | Scalars['Float']['input']>;
  onlyBookmarks: InputMaybe<Scalars['Boolean']['input']>;
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  order: InputMaybe<IOrdering>;
}>;


export type IGetAnonymizedVideosQuery = { __typename?: 'Query', anonymizedVideos: { __typename?: 'AnonymizedVideos', offset: number, limit: number, totalCount: number, videos: Array<{ __typename?: 'AnonymizedVideoWithCycleAndBookmark', id: string, description: string | null, duration: number, timestampStart: any, timestampEnd: any, tagIds: Array<string>, video: { __typename?: 'S3Object', presignedUrl: string }, bookmark: { __typename?: 'AnonymizedVideoBookmark', id: string } | null }> } };

export type IGetAnonymizedVideoByIdQueryVariables = Exact<{
  videoId: Scalars['ID']['input'];
}>;


export type IGetAnonymizedVideoByIdQuery = { __typename?: 'Query', anonymizedVideoByPk: { __typename?: 'AnonymizedVideoWithCycleAndBookmark', id: string, tagIds: Array<string>, description: string | null, duration: number, timestampStart: any, timestampEnd: any, video: { __typename?: 'S3Object', presignedUrl: string }, bookmark: { __typename?: 'AnonymizedVideoBookmark', id: string } | null } | null };

export type IUpdateVideoDescriptionMutationVariables = Exact<{
  videoId: Scalars['ID']['input'];
  description: Scalars['String']['input'];
}>;


export type IUpdateVideoDescriptionMutation = { __typename?: 'Mutation', updateAnonymizedVideo: { __typename: 'AnonymizedVideo', id: string } };

export type ICreateVideoBookmarkMutationVariables = Exact<{
  videoId: Scalars['ID']['input'];
}>;


export type ICreateVideoBookmarkMutation = { __typename?: 'Mutation', createVideoBookmark: { __typename: 'AnonymizedVideoBookmark', id: string } | { __typename: 'AnonymizedVideoCreationFailed', message: string } | { __typename: 'AnonymizedVideoNotFound', message: string } };

export type IDeleteVideoBookmarkMutationVariables = Exact<{
  videoId: Scalars['ID']['input'];
}>;


export type IDeleteVideoBookmarkMutation = { __typename?: 'Mutation', deleteVideoBookmark: { __typename: 'AnonymizedVideoBookmarkNotFound', message: string } | { __typename: 'AnonymizedVideoNotFound', message: string } | { __typename: 'DeletedAnonymizedVideoBookmarkId', videoId: string } };

export const LineFragmentFragmentDoc = `
    fragment LineFragment on LinesWithMetrics {
  data {
    id
    name
    shortName
    factoryId
    metrics {
      type
      target
      average
      values {
        type
        value
        overTime {
          date
          value
        }
      }
    }
  }
}
    `;
export const StationFragmentFragmentDoc = `
    fragment StationFragment on StationsWithMetrics {
  data {
    id
    name
    shortName
    lineId
    factoryId
    metrics {
      type
      target
      average
      values {
        type
        value
        overTime {
          date
          value
        }
      }
    }
  }
}
    `;
export const GetAreaGroupsByTypeDocument = `
    query getAreaGroupsByType($groupType: Int!, $customerId: ID!) {
  areaGroups(filters: {groupType: $groupType, customer: {id: $customerId}}) {
    id
    name
    color
    groupType
  }
}
    `;
export const CreateAreaGroupDocument = `
    mutation createAreaGroup($customerId: ID!, $name: String!, $color: [Int!]!) {
  createAreaGroup(data: {name: $name, color: $color, customer: {id: $customerId}}) {
    __typename
    ... on AreaGroup {
      id
    }
    ... on CustomerNotFound {
      message
    }
    ... on InvalidGroupName {
      message
    }
    ... on InvalidGroupColor {
      message
    }
  }
}
    `;
export const UpdateAreaGroupDocument = `
    mutation updateAreaGroup($id: ID!, $name: String!, $color: [Int!]!) {
  updateAreaGroup(data: {id: $id, name: $name, color: $color}) {
    __typename
    ... on AreaGroup {
      id
    }
    ... on AreaGroupNotFound {
      message
    }
    ... on InvalidGroupName {
      message
    }
    ... on InvalidGroupColor {
      message
    }
  }
}
    `;
export const DeleteAreaGroupDocument = `
    mutation deleteAreaGroup($id: ID!) {
  deleteAreaGroup(data: {id: $id}) {
    __typename
    ... on DeletedAreaGroupId {
      id
    }
    ... on AreaGroupNotFound {
      message
    }
  }
}
    `;
export const GetVirtualSensorAreasLayoutByLineIdDocument = `
    query getVirtualSensorAreasLayoutByLineId($lineId: ID!) {
  cameras(
    filters: {camType: {exact: 2}, stations: {line: {id: {exact: $lineId}}}}
  ) {
    id
    resWidth
    resHeight
    areas {
      id
      group {
        id
        name
        color
        groupType
      }
      coordinates
      virtualBbox {
        id
      }
      downstreamAreas {
        id
        cameraStation {
          station {
            id
            name
            deactivated
          }
        }
      }
    }
  }
}
    `;
export const GetAreaConnectionsDocument = `
    query getAreaConnections($fromArea: ID!, $dateRanges: [Date!]!, $exludedWeekdays: [Int!]!, $shiftGroupIds: [ID!]!) {
  areaConnections(
    filters: {dateRange: $dateRanges, exludedWeekdays: $exludedWeekdays, shiftGroupIds: $shiftGroupIds, fromArea: $fromArea}
  ) {
    connections {
      cameraBoundingBox {
        id
      }
      count
    }
  }
}
    `;
export const GetVirtualSensorImageByLineIdDocument = `
    query getVirtualSensorImageByLineId($lineId: ID!) {
  cameras(
    filters: {camType: {exact: 2}, stations: {line: {id: {exact: $lineId}}}}
  ) {
    id
    latestSnapshot {
      presignedUrl
    }
    resWidth
    resHeight
  }
}
    `;
export const GetAreaOfInterestsStatisticsDocument = `
    query getAreaOfInterestsStatistics($boxId: ID!, $dateRanges: [Date!]!, $shiftGroupIds: [ID!]!, $exludedWeekdays: [Int!]!) {
  areaOfInterestsStatistics: areaOfInterestsStatisticsV2(
    filters: {boxId: $boxId, dateRange: $dateRanges, shiftGroupIds: $shiftGroupIds, exludedWeekdays: $exludedWeekdays}
  ) {
    __typename
    ... on AreaOfInterestStatisticsDTO {
      timeSpentRatio
      avgTimeSpent {
        time
        durationSeconds
      }
      avgWorkerCount {
        time
        workerCount
        timeSpentRatio
      }
    }
    ... on MissingRequiredFields {
      message
    }
  }
}
    `;
export const GetHeatmapByCameraIdDocument = `
    query getHeatmapByCameraId($cameraId: ID!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!) {
  heatmap(
    filters: {camera: {id: $cameraId}, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, limit: 1000}
  ) {
    heatmap
  }
}
    `;
export const GetAreasOfInterestByCameraIdDocument = `
    query getAreasOfInterestByCameraId($cameraId: ID!) {
  cameraBoundingBoxes(filters: {camera: {id: $cameraId}}) {
    id
    camera {
      pk
    }
    virtualBbox {
      id
    }
    downstreamAreas {
      id
    }
    cameraStation {
      id
      station {
        id
        name
      }
    }
    group {
      id
      name
      color
      groupType
    }
    coordinates
  }
}
    `;
export const CreateAreaOfInterestDocument = `
    mutation createAreaOfInterest($cameraId: ID!, $groupId: ID!, $coordinates: JSON!) {
  createCameraBoundingBox(
    data: {camera: {id: $cameraId}, group: {id: $groupId}, coordinates: $coordinates}
  ) {
    __typename
    ... on CameraBoundingBox {
      id
    }
    ... on MissingRequiredFields {
      message
    }
    ... on CameraNotFound {
      message
    }
    ... on StationNotFound {
      message
    }
    ... on GroupNotFound {
      message
    }
    ... on DifferentGroupType {
      message
    }
    ... on AreaHasOverlap {
      message
    }
    ... on InvalidCameraBoundingBoxCoordinates {
      message
    }
  }
}
    `;
export const UpdateAreaOfInterestDocument = `
    mutation updateAreaOfInterest($id: ID!, $cameraId: ID!, $groupId: ID!, $coordinates: JSON!, $stationId: ID, $virtualBoundingBoxId: ID) {
  updateCameraBoundingBox(
    data: {id: $id, camera: {id: $cameraId}, group: {id: $groupId}, station: {id: $stationId}, coordinates: $coordinates, virtualBbox: {id: $virtualBoundingBoxId}}
  ) {
    __typename
    ... on CameraBoundingBox {
      id
    }
    ... on AreaNotFound {
      message
    }
    ... on GroupNotFound {
      message
    }
    ... on CameraNotFound {
      message
    }
    ... on StationNotFound {
      message
    }
    ... on DifferentGroupType {
      message
    }
    ... on AreaHasOverlap {
      message
    }
    ... on InvalidCameraBoundingBoxCoordinates {
      message
    }
    ... on MissingRequiredFields {
      message
    }
  }
}
    `;
export const DeleteAreaOfInterestDocument = `
    mutation deleteAreaOfInterest($id: ID!) {
  deleteBoundingBox(data: {id: $id}) {
    __typename
    ... on DeletedCameraBoundingBox {
      id
    }
    ... on AreaNotFound {
      message
    }
  }
}
    `;
export const GetCamerasByLineIdDocument = `
    query getCamerasByLineId($lineId: ID!) {
  cameras(filters: {stations: {line: {id: {exact: $lineId}}}}) {
    id
    camType
    kinesisVideoStream {
      name
      arn
    }
    latestSnapshot {
      presignedUrl
    }
    resWidth
    resHeight
    areas {
      id
      coordinates
      group {
        id
        color
        name
        groupType
      }
    }
  }
}
    `;
export const GetStationCyclesAndEventsDocument = `
    query getStationCyclesAndEvents($timestampStart: DateTime!, $timestampEnd: DateTime!, $stationId: ID!) {
  getStationCyclesAndEventsData(
    filters: {timestampStart: $timestampStart, timestampEnd: $timestampEnd, stationId: $stationId}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on StationCyclesAndEventsDTO {
      cycles {
        cycleId
        variantId
        type
        timestampStart
        timestampEnd
        videoId
      }
      events {
        id
        type
        timestamp
        metadata
        areaId
        stationId
        cameraId
      }
      timeWindows {
        variantId
        type
        startTime
        endTime
      }
    }
  }
}
    `;
export const GetLinesWithMetricsDocument = `
    query getLinesWithMetrics($factoryId: ID!, $exludedWeekdays: [Int!]!, $dateRanges: [Date!]!, $timeRanges: [[Time!]!]!) {
  linesWithMetrics(
    filters: {factoryId: $factoryId, exludedWeekdays: $exludedWeekdays, timeRanges: $timeRanges, dateRanges: [$dateRanges]}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LinesWithMetrics {
      timeGranularity
      ...LineFragment
    }
  }
}
    ${LineFragmentFragmentDoc}`;
export const GetLineOverallStatisticsDocument = `
    query getLineOverallStatistics($lineId: ID!, $dateRange: [Date!]!, $exludedWeekdays: [Int!]!, $shiftGroupIds: [ID!]!, $tagIds: [ID!]!, $stationIds: [ID!]!, $productIds: [ID!]!) {
  lineOverallStatistics(
    filters: {lineId: $lineId, dateRange: $dateRange, exludedWeekdays: $exludedWeekdays, shiftGroupIds: $shiftGroupIds, tagIds: $tagIds, stationIds: $stationIds, productIds: $productIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineOverallStatisticsDTO {
      output {
        value
        target
      }
      meanCycleTime {
        value
        target
      }
      activeTimePercentage
    }
  }
}
    `;
export const GetLineAverageNumberOfWorkersDocument = `
    query getLineAverageNumberOfWorkers($lineId: ID!, $dateRange: [Date!]!, $exludedWeekdays: [Int!]!, $shiftGroupIds: [ID!]!, $tagIds: [ID!]!, $stationIds: [ID!]!, $productIds: [ID!]!) {
  lineAverageNumberOfWorkers(
    filters: {lineId: $lineId, dateRange: $dateRange, exludedWeekdays: $exludedWeekdays, shiftGroupIds: $shiftGroupIds, tagIds: $tagIds, stationIds: $stationIds, productIds: $productIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineAverageNumberOfWorkersDTO {
      value
      target
    }
  }
}
    `;
export const GetLineStatisticsAccumulatedByTimeDocument = `
    query getLineStatisticsAccumulatedByTime($lineId: ID!, $excludedWeekdays: [Int!]!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!, $tagIds: [ID!]!) {
  lineAccumulatedByTimeStatistics(
    filters: {lineId: $lineId, exludedWeekdays: $excludedWeekdays, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, tagIds: $tagIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineAccumulatedByTimeStatisticsDTO {
      timeGranularity
      data {
        datetime
        aggregationCount
        aggregationTarget
      }
      workingHours
    }
  }
}
    `;
export const GetLineOutputPerStationStatisticsDocument = `
    query getLineOutputPerStationStatistics($lineId: ID!, $excludedWeekdays: [Int!]!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!, $tagIds: [ID!]!, $productIds: [ID!]!) {
  lineOutputPerStationStatistics(
    filters: {lineId: $lineId, exludedWeekdays: $excludedWeekdays, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, tagIds: $tagIds, productIds: $productIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineOutputPerStationStatisticsDTO {
      data {
        stationId
        single {
          key
          value
        }
        combined {
          key
          value
        }
        compared {
          key
          value
        }
      }
    }
  }
}
    `;
export const GetLineAverageCycleTimeByStationDocument = `
    query getLineAverageCycleTimeByStation($lineId: ID!, $excludedWeekdays: [Int!]!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!, $tagIds: [ID!]!, $productIds: [ID!]!) {
  lineAverageCycleTimeByStation(
    filters: {lineId: $lineId, exludedWeekdays: $excludedWeekdays, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, tagIds: $tagIds, productIds: $productIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineAverageCycleTimeByStationDTO {
      taktTime
      data {
        stationId
        single {
          key
          value
        }
        compared {
          key
          value
        }
      }
    }
  }
}
    `;
export const GetLineActivityPerStationStatisticsDocument = `
    query getLineActivityPerStationStatistics($lineId: ID!, $excludedWeekdays: [Int!]!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!) {
  lineActivityPerStationStatistics(
    filters: {lineId: $lineId, exludedWeekdays: $excludedWeekdays, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on LineActivityPerStationStatisticsDTO {
      data {
        stationId
        data {
          tagId
          data {
            key
            value
          }
        }
      }
    }
  }
}
    `;
export const GetNotificationsDocument = `
    query getNotifications($isRead: Boolean, $publishedAt: DateTime!, $limit: Int!, $offset: Int!) {
  notifications(
    filters: {isRead: $isRead, publishedAt: $publishedAt, limit: $limit, offset: $offset}
  ) {
    data {
      id
      publishedAt
      isRead
      notificationRule {
        id
        title
        ruleType
        kpiMetric
        relation
        station {
          id
        }
        medium
        context {
          periodSeconds
          thresholdType
          thresholdValue
        }
      }
    }
    totalCount
    limit
    offset
  }
}
    `;
export const GetNotificationByPkDocument = `
    query getNotificationByPk($notificationId: ID!) {
  notificationByPk(filters: {id: $notificationId}) {
    id
    publishedAt
    isRead
    notificationRule {
      id
      title
      ruleType
      kpiMetric
      relation
      station {
        id
      }
      medium
      context {
        periodSeconds
        thresholdType
        thresholdValue
      }
    }
  }
}
    `;
export const UpdateNotificationDocument = `
    mutation updateNotification($notificationId: ID!, $isRead: Boolean, $publishedAt: DateTime) {
  updateNotification(
    data: {id: $notificationId, isRead: $isRead, publishedAt: $publishedAt}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on NotificationEvent {
      id
      publishedAt
      isRead
      notificationRule {
        id
        title
        ruleType
        kpiMetric
        relation
        station {
          id
        }
        medium
        context {
          periodSeconds
          thresholdType
          thresholdValue
        }
      }
    }
  }
}
    `;
export const GetNotificationRulesDocument = `
    query getNotificationRules($lineId: ID!) {
  notificationRules(filters: {line: {id: $lineId}}) {
    id
    title
    ruleType
    kpiMetric
    relation
    station {
      id
    }
    medium
    context {
      periodSeconds
      thresholdType
      thresholdValue
    }
  }
}
    `;
export const CreateNotificationRuleDocument = `
    mutation createNotificationRule($data: NotificationRuleCreateInput!) {
  createNotificationRule(data: $data) {
    __typename
    ... on SystemError {
      message
    }
    ... on NotificationRule {
      id
      title
      ruleType
      kpiMetric
      relation
      station {
        id
      }
      medium
      context {
        periodSeconds
        thresholdType
        thresholdValue
      }
    }
  }
}
    `;
export const UpdateNotificationRuleDocument = `
    mutation updateNotificationRule($data: NotificationRuleUpdateInput!) {
  updateNotificationRule(data: $data) {
    __typename
    ... on SystemError {
      message
    }
    ... on UserError {
      message
    }
    ... on NotificationRule {
      id
      title
      ruleType
      kpiMetric
      relation
      station {
        id
      }
      medium
      context {
        periodSeconds
        thresholdType
        thresholdValue
      }
    }
  }
}
    `;
export const DeleteNotificationRuleDocument = `
    mutation deleteNotificationRule($data: NotificationRuleDeleteInput!) {
  deleteNotificationRule(data: $data) {
    __typename
    ... on SystemError {
      message
    }
    ... on UserError {
      message
    }
    ... on DeletedNotificationRuleId {
      id
    }
  }
}
    `;
export const GetFactoriesWithLinesAndStationsDocument = `
    query getFactoriesWithLinesAndStations {
  organizationalLevels {
    id
    name
    customerId
    lines {
      id
      name
      inTraining
      shifts {
        id
        name
        start
        end
      }
      stations {
        id
        name
        isOutput
        type
      }
    }
  }
}
    `;
export const GetShiftsDocument = `
    query getShifts($lineId: ID!, $applicableDate: Date) {
  shifts(filters: {line: {id: $lineId}}) {
    __typename
    ... on ShiftGroups {
      data {
        id
        start
        end
        name
        variants(filters: {applicableDate: $applicableDate}) {
          id
          name
          disabled
          repeatStart
          repeatEvery
          repeatInterval
          weekdaysTargets {
            output
            cycleTime
            persons
          }
          workingTimes
        }
      }
    }
  }
}
    `;
export const CreateShiftVariantDocument = `
    mutation createShiftVariant($input: CreateShiftVariantInput!) {
  createShiftVariant(data: $input) {
    __typename
    ... on UserError {
      message
    }
    ... on ShiftVariant {
      id
      name
      shiftGroup {
        id
      }
      disabled
      repeatStart
      repeatEvery
      repeatInterval
      weekdaysTargets {
        output
        cycleTime
        persons
      }
      workingTimes
    }
  }
}
    `;
export const UpdateShiftVariantDocument = `
    mutation updateShiftVariant($input: UpdateShiftVariantInput!) {
  updateShiftVariant(data: $input) {
    __typename
    ... on UserError {
      message
    }
    ... on ShiftVariant {
      id
      name
      shiftGroup {
        id
      }
      disabled
      repeatStart
      repeatEvery
      repeatInterval
      weekdaysTargets {
        output
        cycleTime
        persons
      }
      workingTimes
    }
  }
}
    `;
export const CreateShiftVariantOverrideDocument = `
    mutation createShiftVariantOverride($input: CreateShiftVariantOverrideInput!) {
  createShiftVariantOverride(data: $input) {
    __typename
    ... on UserError {
      message
    }
    ... on CreatedShiftVariantOverrideId {
      id
    }
  }
}
    `;
export const DeleteShiftVariantDocument = `
    mutation deleteShiftVariant($input: DeleteShiftVariantInput!) {
  deleteShiftVariant(data: $input) {
    __typename
    ... on UserError {
      message
    }
    ... on DeletedShiftVariantId {
      id
    }
  }
}
    `;
export const GetStationStatisticsDocument = `
    query getStationStatistics($stationId: ID!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!) {
  histogram(
    filters: {stationId: $stationId, dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, binWindow: 0.2}
  ) {
    __typename
    cycleCount
    duration {
      median
      mean
      variance
    }
    durationTarget {
      total
      mean
    }
    data {
      range
      cycleCount
    }
  }
  cycleOverTimeStatistics(
    filters: {stationIds: [$stationId], dateRange: $dateRange, shiftGroupIds: $shiftGroupIds}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on CycleOverTimeStatistics {
      timeGranularity
      data {
        datetime
        totalDuration: duration
        count
      }
    }
  }
}
    `;
export const GetStationsWithMetricsDocument = `
    query getStationsWithMetrics($lineId: ID!, $exludedWeekdays: [Int!]!, $dateRanges: [Date!]!, $timeRanges: [[Time!]!]!) {
  stationsWithMetrics(
    filters: {lineId: $lineId, exludedWeekdays: $exludedWeekdays, timeRanges: $timeRanges, dateRanges: [$dateRanges]}
  ) {
    __typename
    ... on UserError {
      message
    }
    ... on StationsWithMetrics {
      timeGranularity
      ...StationFragment
    }
  }
}
    ${StationFragmentFragmentDoc}`;
export const GetAllTagsDocument = `
    query getAllTags {
  tags(filters: {taggables: {}}) {
    id
    name
    color
    tagType
  }
}
    `;
export const CreateTagDocument = `
    mutation createTag($name: String!) {
  createTag(data: {name: $name, tagType: 1}) {
    __typename
    ... on Tag {
      id
      name
      color
    }
    ... on UserError {
      message
    }
    ... on SystemError {
      message
    }
  }
}
    `;
export const TagVideoDocument = `
    mutation tagVideo($tagId: ID!, $taggedModelId: ID!) {
  tagModel(
    data: {tagId: $tagId, taggedModelId: $taggedModelId, taggedModelType: "media_anonymizedvideo"}
  ) {
    __typename
    ... on TagTaggable {
      id
      modelId
      modelName
      tag {
        id
        name
        color
      }
    }
    ... on UserError {
      message
    }
    ... on SystemError {
      message
    }
  }
}
    `;
export const UntagVideoDocument = `
    mutation untagVideo($tagId: ID!, $modelId: ID!) {
  untagModel(
    data: {tagId: $tagId, modelId: $modelId, modelName: "anonymizedvideo"}
  ) {
    __typename
    ... on UnexpectedError {
      message
    }
    ... on SystemError {
      message
    }
    ... on UntagModelId {
      modelId
    }
  }
}
    `;
export const GetUserPreferencesDocument = `
    query getUserPreferences {
  userPreferences {
    __typename
    ... on SystemError {
      message
    }
    ... on UserPreferences {
      userId
      onboardingGuides
    }
  }
}
    `;
export const SaveUserPreferencesDocument = `
    mutation saveUserPreferences($onboardingGuides: JSON!) {
  saveUserPreferences(data: {onboardingGuides: $onboardingGuides}) {
    __typename
    ... on UserError {
      message
    }
    ... on SystemError {
      message
    }
    ... on UserPreferences {
      userId
      onboardingGuides
    }
  }
}
    `;
export const GetAnonymizedVideosDocument = `
    query getAnonymizedVideos($stationIds: [ID!]!, $tagIds: [ID!]!, $dateRange: [Date!]!, $shiftGroupIds: [ID!]!, $durationRange: [Float!], $onlyBookmarks: Boolean, $limit: Int!, $offset: Int!, $order: Ordering) {
  anonymizedVideos: anonymizedVideosV2(
    filters: {dateRange: $dateRange, shiftGroupIds: $shiftGroupIds, stationIds: $stationIds, tagIds: $tagIds, durationRange: $durationRange, onlyBookmarked: $onlyBookmarks, limit: $limit, offset: $offset, order: $order}
  ) {
    videos {
      id
      video {
        presignedUrl
      }
      bookmark {
        id
      }
      description
      duration
      timestampStart
      timestampEnd
      tagIds
    }
    offset
    limit
    totalCount
  }
}
    `;
export const GetAnonymizedVideoByIdDocument = `
    query getAnonymizedVideoById($videoId: ID!) {
  anonymizedVideoByPk(filters: {id: {exact: $videoId, isNull: false}}) {
    id
    video {
      presignedUrl
    }
    bookmark {
      id
    }
    tagIds
    description
    duration
    timestampStart
    timestampEnd
  }
}
    `;
export const UpdateVideoDescriptionDocument = `
    mutation updateVideoDescription($videoId: ID!, $description: String!) {
  updateAnonymizedVideo(
    data: {id: $videoId, status: {name: "done"}, description: $description}
  ) {
    __typename
    id
  }
}
    `;
export const CreateVideoBookmarkDocument = `
    mutation createVideoBookmark($videoId: ID!) {
  createVideoBookmark(data: {id: $videoId}) {
    __typename
    ... on UserError {
      message
    }
    ... on AnonymizedVideoBookmark {
      id
    }
  }
}
    `;
export const DeleteVideoBookmarkDocument = `
    mutation deleteVideoBookmark($videoId: ID!) {
  deleteVideoBookmark(data: {id: $videoId}) {
    __typename
    ... on UserError {
      message
    }
    ... on DeletedAnonymizedVideoBookmarkId {
      videoId
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string, operationType?: string, variables?: any) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName, _operationType, _variables) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    getAreaGroupsByType(variables: IGetAreaGroupsByTypeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAreaGroupsByTypeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAreaGroupsByTypeQuery>(GetAreaGroupsByTypeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAreaGroupsByType', 'query', variables);
    },
    createAreaGroup(variables: ICreateAreaGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateAreaGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateAreaGroupMutation>(CreateAreaGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAreaGroup', 'mutation', variables);
    },
    updateAreaGroup(variables: IUpdateAreaGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateAreaGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateAreaGroupMutation>(UpdateAreaGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAreaGroup', 'mutation', variables);
    },
    deleteAreaGroup(variables: IDeleteAreaGroupMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteAreaGroupMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteAreaGroupMutation>(DeleteAreaGroupDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAreaGroup', 'mutation', variables);
    },
    getVirtualSensorAreasLayoutByLineId(variables: IGetVirtualSensorAreasLayoutByLineIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetVirtualSensorAreasLayoutByLineIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetVirtualSensorAreasLayoutByLineIdQuery>(GetVirtualSensorAreasLayoutByLineIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getVirtualSensorAreasLayoutByLineId', 'query', variables);
    },
    getAreaConnections(variables: IGetAreaConnectionsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAreaConnectionsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAreaConnectionsQuery>(GetAreaConnectionsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAreaConnections', 'query', variables);
    },
    getVirtualSensorImageByLineId(variables: IGetVirtualSensorImageByLineIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetVirtualSensorImageByLineIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetVirtualSensorImageByLineIdQuery>(GetVirtualSensorImageByLineIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getVirtualSensorImageByLineId', 'query', variables);
    },
    getAreaOfInterestsStatistics(variables: IGetAreaOfInterestsStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAreaOfInterestsStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAreaOfInterestsStatisticsQuery>(GetAreaOfInterestsStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAreaOfInterestsStatistics', 'query', variables);
    },
    getHeatmapByCameraId(variables: IGetHeatmapByCameraIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetHeatmapByCameraIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetHeatmapByCameraIdQuery>(GetHeatmapByCameraIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getHeatmapByCameraId', 'query', variables);
    },
    getAreasOfInterestByCameraId(variables: IGetAreasOfInterestByCameraIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAreasOfInterestByCameraIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAreasOfInterestByCameraIdQuery>(GetAreasOfInterestByCameraIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAreasOfInterestByCameraId', 'query', variables);
    },
    createAreaOfInterest(variables: ICreateAreaOfInterestMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateAreaOfInterestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateAreaOfInterestMutation>(CreateAreaOfInterestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createAreaOfInterest', 'mutation', variables);
    },
    updateAreaOfInterest(variables: IUpdateAreaOfInterestMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateAreaOfInterestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateAreaOfInterestMutation>(UpdateAreaOfInterestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateAreaOfInterest', 'mutation', variables);
    },
    deleteAreaOfInterest(variables: IDeleteAreaOfInterestMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteAreaOfInterestMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteAreaOfInterestMutation>(DeleteAreaOfInterestDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteAreaOfInterest', 'mutation', variables);
    },
    getCamerasByLineId(variables: IGetCamerasByLineIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetCamerasByLineIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetCamerasByLineIdQuery>(GetCamerasByLineIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getCamerasByLineId', 'query', variables);
    },
    getStationCyclesAndEvents(variables: IGetStationCyclesAndEventsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetStationCyclesAndEventsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetStationCyclesAndEventsQuery>(GetStationCyclesAndEventsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStationCyclesAndEvents', 'query', variables);
    },
    getLinesWithMetrics(variables: IGetLinesWithMetricsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLinesWithMetricsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLinesWithMetricsQuery>(GetLinesWithMetricsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLinesWithMetrics', 'query', variables);
    },
    getLineOverallStatistics(variables: IGetLineOverallStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineOverallStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineOverallStatisticsQuery>(GetLineOverallStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineOverallStatistics', 'query', variables);
    },
    getLineAverageNumberOfWorkers(variables: IGetLineAverageNumberOfWorkersQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineAverageNumberOfWorkersQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineAverageNumberOfWorkersQuery>(GetLineAverageNumberOfWorkersDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineAverageNumberOfWorkers', 'query', variables);
    },
    getLineStatisticsAccumulatedByTime(variables: IGetLineStatisticsAccumulatedByTimeQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineStatisticsAccumulatedByTimeQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineStatisticsAccumulatedByTimeQuery>(GetLineStatisticsAccumulatedByTimeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineStatisticsAccumulatedByTime', 'query', variables);
    },
    getLineOutputPerStationStatistics(variables: IGetLineOutputPerStationStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineOutputPerStationStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineOutputPerStationStatisticsQuery>(GetLineOutputPerStationStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineOutputPerStationStatistics', 'query', variables);
    },
    getLineAverageCycleTimeByStation(variables: IGetLineAverageCycleTimeByStationQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineAverageCycleTimeByStationQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineAverageCycleTimeByStationQuery>(GetLineAverageCycleTimeByStationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineAverageCycleTimeByStation', 'query', variables);
    },
    getLineActivityPerStationStatistics(variables: IGetLineActivityPerStationStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetLineActivityPerStationStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetLineActivityPerStationStatisticsQuery>(GetLineActivityPerStationStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getLineActivityPerStationStatistics', 'query', variables);
    },
    getNotifications(variables: IGetNotificationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetNotificationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetNotificationsQuery>(GetNotificationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNotifications', 'query', variables);
    },
    getNotificationByPk(variables: IGetNotificationByPkQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetNotificationByPkQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetNotificationByPkQuery>(GetNotificationByPkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNotificationByPk', 'query', variables);
    },
    updateNotification(variables: IUpdateNotificationMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateNotificationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateNotificationMutation>(UpdateNotificationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNotification', 'mutation', variables);
    },
    getNotificationRules(variables: IGetNotificationRulesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetNotificationRulesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetNotificationRulesQuery>(GetNotificationRulesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getNotificationRules', 'query', variables);
    },
    createNotificationRule(variables: ICreateNotificationRuleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateNotificationRuleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateNotificationRuleMutation>(CreateNotificationRuleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createNotificationRule', 'mutation', variables);
    },
    updateNotificationRule(variables: IUpdateNotificationRuleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateNotificationRuleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateNotificationRuleMutation>(UpdateNotificationRuleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateNotificationRule', 'mutation', variables);
    },
    deleteNotificationRule(variables: IDeleteNotificationRuleMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteNotificationRuleMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteNotificationRuleMutation>(DeleteNotificationRuleDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteNotificationRule', 'mutation', variables);
    },
    getFactoriesWithLinesAndStations(variables?: IGetFactoriesWithLinesAndStationsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetFactoriesWithLinesAndStationsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetFactoriesWithLinesAndStationsQuery>(GetFactoriesWithLinesAndStationsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getFactoriesWithLinesAndStations', 'query', variables);
    },
    getShifts(variables: IGetShiftsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetShiftsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetShiftsQuery>(GetShiftsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getShifts', 'query', variables);
    },
    createShiftVariant(variables: ICreateShiftVariantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateShiftVariantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateShiftVariantMutation>(CreateShiftVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createShiftVariant', 'mutation', variables);
    },
    updateShiftVariant(variables: IUpdateShiftVariantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateShiftVariantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateShiftVariantMutation>(UpdateShiftVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateShiftVariant', 'mutation', variables);
    },
    createShiftVariantOverride(variables: ICreateShiftVariantOverrideMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateShiftVariantOverrideMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateShiftVariantOverrideMutation>(CreateShiftVariantOverrideDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createShiftVariantOverride', 'mutation', variables);
    },
    deleteShiftVariant(variables: IDeleteShiftVariantMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteShiftVariantMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteShiftVariantMutation>(DeleteShiftVariantDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteShiftVariant', 'mutation', variables);
    },
    getStationStatistics(variables: IGetStationStatisticsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetStationStatisticsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetStationStatisticsQuery>(GetStationStatisticsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStationStatistics', 'query', variables);
    },
    getStationsWithMetrics(variables: IGetStationsWithMetricsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetStationsWithMetricsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetStationsWithMetricsQuery>(GetStationsWithMetricsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getStationsWithMetrics', 'query', variables);
    },
    getAllTags(variables?: IGetAllTagsQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAllTagsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAllTagsQuery>(GetAllTagsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAllTags', 'query', variables);
    },
    createTag(variables: ICreateTagMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateTagMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateTagMutation>(CreateTagDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createTag', 'mutation', variables);
    },
    tagVideo(variables: ITagVideoMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ITagVideoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ITagVideoMutation>(TagVideoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'tagVideo', 'mutation', variables);
    },
    untagVideo(variables: IUntagVideoMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUntagVideoMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUntagVideoMutation>(UntagVideoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'untagVideo', 'mutation', variables);
    },
    getUserPreferences(variables?: IGetUserPreferencesQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetUserPreferencesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetUserPreferencesQuery>(GetUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getUserPreferences', 'query', variables);
    },
    saveUserPreferences(variables: ISaveUserPreferencesMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ISaveUserPreferencesMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ISaveUserPreferencesMutation>(SaveUserPreferencesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'saveUserPreferences', 'mutation', variables);
    },
    getAnonymizedVideos(variables: IGetAnonymizedVideosQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAnonymizedVideosQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAnonymizedVideosQuery>(GetAnonymizedVideosDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAnonymizedVideos', 'query', variables);
    },
    getAnonymizedVideoById(variables: IGetAnonymizedVideoByIdQueryVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IGetAnonymizedVideoByIdQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<IGetAnonymizedVideoByIdQuery>(GetAnonymizedVideoByIdDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'getAnonymizedVideoById', 'query', variables);
    },
    updateVideoDescription(variables: IUpdateVideoDescriptionMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IUpdateVideoDescriptionMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IUpdateVideoDescriptionMutation>(UpdateVideoDescriptionDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'updateVideoDescription', 'mutation', variables);
    },
    createVideoBookmark(variables: ICreateVideoBookmarkMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<ICreateVideoBookmarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<ICreateVideoBookmarkMutation>(CreateVideoBookmarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createVideoBookmark', 'mutation', variables);
    },
    deleteVideoBookmark(variables: IDeleteVideoBookmarkMutationVariables, requestHeaders?: GraphQLClientRequestHeaders): Promise<IDeleteVideoBookmarkMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<IDeleteVideoBookmarkMutation>(DeleteVideoBookmarkDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'deleteVideoBookmark', 'mutation', variables);
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;