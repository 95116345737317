import { z } from "zod";

import { entitySchema } from "./common/entity";
import { customerIdSchema } from "./customer";

export const factoryIdSchema = z.string().brand<"FactoryId">();
export const factorySchema = entitySchema.extend({
  id: factoryIdSchema,
  customerId: customerIdSchema,
});

export type FactoryId = z.infer<typeof factoryIdSchema>;
