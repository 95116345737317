import { z } from "zod";

import { paginationSchema } from "./common/pagination";
import { dateRangeSchema } from "./common/time-filter";
import { shiftIdSchema } from "./shifts";
import { stationIdSchema } from "./station";
import { tagIdSchema, tagSchema } from "./tag";

export const videoIdSchema = z.string().brand<"VideoId">();

export const videoSchema = z.object({
  id: videoIdSchema,
  description: z.string().nullable(),
  duration: z.number().nonnegative(),
  timestampStart: z.date(),
  timestampEnd: z.date(),
  url: z.string().url(),
  bookmarked: z.boolean(),
  tagIds: z.array(tagIdSchema),
});

const videoTagSchema = tagSchema.extend({
  modelIds: z.array(videoIdSchema),
});

const videosFiltersSchema = z
  .object({
    stationIds: z.array(stationIdSchema),
    tagIds: z.array(tagIdSchema),
    onlyBookmarks: z.boolean().default(false),
    durationRange: z.array(z.number().nonnegative()).min(2).max(2).nullable(),
    dateRange: dateRangeSchema,
    shiftIds: z.array(shiftIdSchema),
  })
  .extend(paginationSchema.shape);

const updateVideoDescriptionParamsSchema = z.object({
  videoId: videoIdSchema,
  description: z.string().min(1),
});

const videoBookmarkParamsSchema = z.object({
  videoId: videoIdSchema,
});

const tagVideoParamsSchema = z.object({
  tagId: tagIdSchema,
  videoId: videoIdSchema,
});

export type VideoId = z.infer<typeof videoIdSchema>;
export type Video = z.infer<typeof videoSchema>;
export type VideoTagDto = z.infer<typeof videoTagSchema>;
export type VideosFilters = z.infer<typeof videosFiltersSchema>;

export type UpdateVideoDescriptionParamsSchema = z.infer<
  typeof updateVideoDescriptionParamsSchema
>;
export type VideoBookmarkParamsSchema = z.infer<
  typeof videoBookmarkParamsSchema
>;
export type TagVideoParamsSchema = z.infer<typeof tagVideoParamsSchema>;

const GetAnonymizedVideoByIdFiltersSchema = z.object({
  videoId: videoIdSchema,
});

export type GetAnonymizedVideoByIdFilters = z.infer<
  typeof GetAnonymizedVideoByIdFiltersSchema
>;
