import { z } from "zod";

import { videoIdSchema } from "@/domain/video";

import { stationIdSchema } from "./station";

const stationCyclesAndEventsFiltersSchema = z.object({
  timestampStart: z.date(),
  timestampEnd: z.date(),
  stationId: stationIdSchema,
});

export const eventTypeSchema = z.union([
  z.literal("product_start"),
  z.literal("product_end"),
  z.literal("person_start"),
  z.literal("person_end"),
]);

export const cycleTypeSchema = z.union([
  z.literal("product"),
  z.literal("person"),
]);

export const timeWindowTypeSchema = z.union([
  z.literal("product"),
  z.literal("person"),
  z.literal("person_product"),
  z.literal("empty"),
]);

const eventSchema = z.object({
  id: z.string(),
  type: eventTypeSchema,
  timestamp: z.date(),
  metadata: z.record(z.unknown()),
  areaId: z.string(),
  stationId: z.string(),
  cameraId: z.string(),
});

const cycleSchema = z.object({
  cycleId: z.string(),
  variantId: z.string(),
  type: cycleTypeSchema,
  timestampStart: z.date(),
  timestampEnd: z.date(),
  videoId: videoIdSchema.nullable(),
});

const timeWindowSchema = z.object({
  variantId: z.string(),
  type: timeWindowTypeSchema,
  startTime: z.date(),
  endTime: z.date(),
});

export const stationCyclesAndEventsSchema = z.object({
  cycles: z.array(cycleSchema),
  events: z.array(eventSchema),
  timeWindows: z.array(timeWindowSchema),
});

export type StationCyclesAndEventsFilters = z.infer<
  typeof stationCyclesAndEventsFiltersSchema
>;
export type StationCyclesAndEvents = z.infer<
  typeof stationCyclesAndEventsSchema
>;
