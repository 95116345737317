import { z } from "zod";

import { dateRangeSchema } from "./common/time-filter";
import { customerIdSchema } from "./customer";
import { lineIdSchema } from "./line";
import { shiftIdSchema } from "./shifts";
import { stationIdSchema } from "./station";

export const MIN_AREA_WIDTH = 10;
export const MIN_AREA_HEIGHT = 10;

export const areaIdSchema = z.string().brand<"AreaId">();
const areaGroupIdSchema = z.string().brand<"AreaGroupId">();

export enum AreaGroupType {
  SYSTEM = 0,
  USER = 1,
}

const areaGroupTypeSchema = z.nativeEnum(AreaGroupType);
const areaColorSchema = z.array(z.number().min(0).max(255)).length(3);
const dimensionsSchema = z.object({
  width: z.number().nonnegative(),
  height: z.number().nonnegative(),
});
const positionSchema = z.object({
  x: z.number().nonnegative(),
  y: z.number().nonnegative(),
});
const areaCoordinatesSchema = dimensionsSchema.extend(positionSchema.shape);
export const absAreaCoordinates =
  areaCoordinatesSchema.brand<"AbsoluteAreaCoordinates">();
export const relAreaCoordinates =
  areaCoordinatesSchema.brand<"RelativeAreaCoordinates">();
const areaConnectionSchema = z.object({
  count: z.number().nonnegative(),
  areaId: areaIdSchema,
});
export const areaGroupSchema = z.object({
  id: areaGroupIdSchema,
  name: z.string().min(1),
  color: areaColorSchema,
  groupType: areaGroupTypeSchema,
});
export const areaSchema = z.object({
  id: areaIdSchema,
  group: areaGroupSchema,
  coordinates: absAreaCoordinates,
  stationId: stationIdSchema.nullable(),
  upstreamAreaId: areaIdSchema.optional(),
  downstreamAreaIds: z.array(areaIdSchema).optional(),
});
const areaCoordinatesByAreaIdSchema = z.record(
  areaIdSchema,
  absAreaCoordinates
);

const getAreaOfInterestsStatisticsFiltersSchema = z.object({
  areaId: areaIdSchema,
  dateRange: dateRangeSchema,
  shiftGroupIds: z.array(shiftIdSchema),
});
const workerCountSchema = z.object({
  time: z.string(),
  workerCount: z.number().nonnegative(),
  timeSpentRatio: z.number().nonnegative().min(0).max(1),
});
const timeSpentSchema = z.object({
  time: z.string(),
  durationSeconds: z.number().nonnegative(),
});
export const areaOfInterestsStatisticsSchema = z.object({
  avgTimeSpent: z.array(timeSpentSchema),
  avgWorkerCount: z.array(workerCountSchema),
});
export type AreaOfInterestsStatistics = z.infer<
  typeof areaOfInterestsStatisticsSchema
>;
export type GetAreaOfInterestStatisticsFilters = z.infer<
  typeof getAreaOfInterestsStatisticsFiltersSchema
>;

export type Area = z.infer<typeof areaSchema>;
export type AreaId = z.infer<typeof areaIdSchema>;
export type AreaGroupId = z.infer<typeof areaGroupIdSchema>;
export type AreaGroup = z.infer<typeof areaGroupSchema>;
export type AreaConnection = z.infer<typeof areaConnectionSchema>;
export type AreaColor = z.infer<typeof areaColorSchema>;
export type Dimensions = z.infer<typeof dimensionsSchema>;
export type Position = z.infer<typeof positionSchema>;
export type AreaCoordinates = z.infer<typeof absAreaCoordinates>;

export type AreaCoordinatesByAreaId = z.infer<
  typeof areaCoordinatesByAreaIdSchema
>;
type RelativeAreaCoordinates = z.infer<typeof relAreaCoordinates>;

export function convertRelativeToAbsoluteCoordinates(
  coordinates: RelativeAreaCoordinates,
  canvasSize: Dimensions
): AreaCoordinates {
  return absAreaCoordinates.parse({
    x: Math.trunc(coordinates.x * canvasSize.width),
    y: Math.trunc(coordinates.y * canvasSize.height),
    width: Math.trunc(coordinates.width * canvasSize.width),
    height: Math.trunc(coordinates.height * canvasSize.height),
  });
}

export function convertAbsoluteToRelativeCoordinates(
  coordinates: AreaCoordinates,
  canvasSize: Dimensions
): RelativeAreaCoordinates {
  return relAreaCoordinates.parse({
    x: coordinates.x / canvasSize.width,
    y: coordinates.y / canvasSize.height,
    width: coordinates.width / canvasSize.width,
    height: coordinates.height / canvasSize.height,
  });
}

const camerasByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});

export enum CameraType {
  Regular = 0,
  BEV = 1,
  Virtual = 2,
}

export const cameraTypeSchema = z.nativeEnum(CameraType);
export const cameraIdSchema = z.string().brand<"CameraId">();

export const cameraSchema = z.object({
  id: cameraIdSchema,
  camType: cameraTypeSchema,
  imgUrl: z.string().url(),
  kinesisVideoStream: z
    .object({
      name: z.string(),
      arn: z.string(),
    })
    .nullable(),
  resWidth: z.number().nonnegative(),
  resHeight: z.number().nonnegative(),
  areas: z.array(areaSchema),
});

export type CamerasByLineIdFilters = z.infer<
  typeof camerasByLineIdFiltersSchema
>;
export type CameraId = z.infer<typeof cameraIdSchema>;
export type Camera = z.infer<typeof cameraSchema>;

const getHeatmapByCameraIdFiltersSchema = z.object({
  cameraId: cameraIdSchema,
  dateRange: dateRangeSchema,
  shiftsIds: z.array(shiftIdSchema),
});
export const heatmapSchema = z.array(
  z.tuple([
    z.number().nonnegative(),
    z.number().nonnegative(),
    z.number().nonnegative(),
  ])
);

export type GetHeatmapByCameraIdFilters = z.infer<
  typeof getHeatmapByCameraIdFiltersSchema
>;
export type Heatmap = z.infer<typeof heatmapSchema>;

const getVirtualSensorImageByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});
export type GetVirtualSensorImageByLineIdFilters = z.infer<
  typeof getVirtualSensorImageByLineIdFiltersSchema
>;

const getVirtualSensorAreasLayoutByLineIdFiltersSchema = z.object({
  lineId: lineIdSchema,
});
export type GetVirtualSensorAreasLayoutByLineIdFilters = z.infer<
  typeof getVirtualSensorAreasLayoutByLineIdFiltersSchema
>;

export const virtualSensorAreasLayoutSchema = z.object({
  id: cameraIdSchema,
  dimensions: dimensionsSchema,
  areas: z.array(areaSchema),
  areaCoordinatesByAreaId: z.record(areaIdSchema, absAreaCoordinates),
});
export type VirtualSensorAreasLayout = z.infer<
  typeof virtualSensorAreasLayoutSchema
>;
const virtualSensorImageSchema = z.object({
  id: cameraIdSchema,
  bgUrl: z.string().url(),
  dimensions: dimensionsSchema,
});
export type VirtualSensorImage = z.infer<typeof virtualSensorImageSchema>;

export const areaConnectionsSchema = z.array(areaConnectionSchema);
export type AreaConnections = z.infer<typeof areaConnectionsSchema>;
const getAreaConnectionsFiltersSchema = z.object({
  areaId: areaIdSchema,
  dateRange: dateRangeSchema,
  shiftGroupIds: z.array(shiftIdSchema),
});
export type GetAreaConnectionsFilters = z.infer<
  typeof getAreaConnectionsFiltersSchema
>;

const getAreaGroupsByTypeFiltersSchema = z.object({
  groupType: areaGroupTypeSchema,
  customerId: customerIdSchema,
});
export type GetAreaGroupsByTypeFilters = z.infer<
  typeof getAreaGroupsByTypeFiltersSchema
>;

const createAreaGroupFiltersSchema = z.object({
  customerId: customerIdSchema,
  name: z.string().nonempty(),
  color: areaColorSchema,
});
export type CreateAreaGroupFilters = z.infer<
  typeof createAreaGroupFiltersSchema
>;

const updateAreaGroupFiltersSchema = z.object({
  id: areaGroupIdSchema,
  name: z.string().nonempty(),
  color: areaColorSchema,
});
export type UpdateAreaGroupFilters = z.infer<
  typeof updateAreaGroupFiltersSchema
>;

const deleteAreaGroupFiltersSchema = z.object({
  id: areaGroupIdSchema,
});
export type DeleteAreaGroupFilters = z.infer<
  typeof deleteAreaGroupFiltersSchema
>;

const getAreasOfInterestByCameraIdFiltersSchema = z.object({
  camera: cameraSchema,
});
export type GetAreasOfInterestByCameraIdFilters = z.infer<
  typeof getAreasOfInterestByCameraIdFiltersSchema
>;

const createAreaOfInterestFiltersSchema = z.object({
  cameraId: cameraIdSchema,
  groupId: areaGroupIdSchema,
  coordinates: relAreaCoordinates,
});
export type CreateAreaOfInterestFilters = z.infer<
  typeof createAreaOfInterestFiltersSchema
>;

const updateAreaOfInterestFiltersSchema = z.object({
  id: areaIdSchema,
  groupId: areaGroupIdSchema,
  cameraId: cameraIdSchema,
  coordinates: relAreaCoordinates,
  stationId: stationIdSchema.nullable(),
  virtualAreaId: areaIdSchema.nullable(),
});
export type UpdateAreaOfInterestFilters = z.infer<
  typeof updateAreaOfInterestFiltersSchema
>;

const deleteAreaOfInterestFiltersSchema = z.object({
  id: areaIdSchema,
});
export type DeleteAreaOfInterestFilters = z.infer<
  typeof deleteAreaOfInterestFiltersSchema
>;

export function isAreaSizeValid(
  coordinates: AreaCoordinates,
  canvasSize: Dimensions
) {
  return (
    coordinates.x >= 0 &&
    coordinates.x + coordinates.width <= canvasSize.width &&
    coordinates.y >= 0 &&
    coordinates.y + coordinates.height <= canvasSize.height &&
    coordinates.width >= MIN_AREA_WIDTH &&
    coordinates.height >= MIN_AREA_HEIGHT
  );
}

function detectCollisionX(current: AreaCoordinates, other: AreaCoordinates) {
  const currentRight = current.x + current.width;
  const otherRight = other.x + other.width;
  return currentRight >= other.x && current.x <= otherRight;
}

function detectCollisionY(current: AreaCoordinates, other: AreaCoordinates) {
  const currentBottom = current.y + current.height;
  const otherBottom = other.y + other.height;
  return currentBottom >= other.y && current.y <= otherBottom;
}

export function detectCollision(
  current: AreaCoordinates,
  other: AreaCoordinates
) {
  return detectCollisionX(current, other) && detectCollisionY(current, other);
}

export function getCoordinatesFromPositions(
  start: Position,
  end: Position
): AreaCoordinates {
  return absAreaCoordinates.parse({
    x: Math.min(start.x, end.x),
    y: Math.min(start.y, end.y),
    height: Math.floor(Math.abs(end.y - start.y)),
    width: Math.floor(Math.abs(end.x - start.x)),
  });
}

export const COLORS: Array<AreaColor> = [
  [244, 67, 54], // red
  [233, 30, 99], // pink
  [156, 39, 176], // purple
  [63, 81, 181], // indigo
  [33, 150, 243], // blue
  [0, 188, 212], // cyan
  [0, 150, 136], // teal
  [76, 175, 80], // green
  [205, 220, 57], // lime
  [255, 235, 59], // yellow
  [255, 193, 7], // amber
  [255, 152, 0], // orange
];

export function isGroupNameValid(name?: string): name is AreaGroup["name"] {
  return areaGroupSchema.shape.name.safeParse(name).success;
}

export function isGroupColorValid(
  color?: AreaGroup["color"]
): color is AreaGroup["color"] {
  return areaColorSchema.safeParse(color).success;
}

export function areColorsEqual(
  colorA: AreaGroup["color"],
  colorB: AreaGroup["color"]
) {
  return colorA.join("-") === colorB.join("-");
}
